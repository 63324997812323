/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.333%;
  max-width: 33.333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.667%;
  max-width: 16.667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.333%;
  max-width: 8.333%; }

.col-2 {
  flex: 0 0 16.667%;
  max-width: 16.667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.333%;
  max-width: 33.333%; }

.col-5 {
  flex: 0 0 41.667%;
  max-width: 41.667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.333%;
  max-width: 58.333%; }

.col-8 {
  flex: 0 0 66.667%;
  max-width: 66.667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.333%;
  max-width: 83.333%; }

.col-11 {
  flex: 0 0 91.667%;
  max-width: 91.667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.333%; }

.offset-2 {
  margin-left: 16.667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333%; }

.offset-5 {
  margin-left: 41.667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333%; }

.offset-8 {
  margin-left: 66.667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333%; }

.offset-11 {
  margin-left: 91.667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.667%;
    max-width: 16.667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%; }
  .col-sm-2 {
    flex: 0 0 16.667%;
    max-width: 16.667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .col-sm-5 {
    flex: 0 0 41.667%;
    max-width: 41.667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%; }
  .col-sm-8 {
    flex: 0 0 66.667%;
    max-width: 66.667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%; }
  .col-sm-11 {
    flex: 0 0 91.667%;
    max-width: 91.667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333%; }
  .offset-sm-2 {
    margin-left: 16.667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333%; }
  .offset-sm-5 {
    margin-left: 41.667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333%; }
  .offset-sm-8 {
    margin-left: 66.667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333%; }
  .offset-sm-11 {
    margin-left: 91.667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.667%;
    max-width: 16.667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%; }
  .col-md-2 {
    flex: 0 0 16.667%;
    max-width: 16.667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .col-md-5 {
    flex: 0 0 41.667%;
    max-width: 41.667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%; }
  .col-md-8 {
    flex: 0 0 66.667%;
    max-width: 66.667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%; }
  .col-md-11 {
    flex: 0 0 91.667%;
    max-width: 91.667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333%; }
  .offset-md-2 {
    margin-left: 16.667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333%; }
  .offset-md-5 {
    margin-left: 41.667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333%; }
  .offset-md-8 {
    margin-left: 66.667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333%; }
  .offset-md-11 {
    margin-left: 91.667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.667%;
    max-width: 16.667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%; }
  .col-lg-2 {
    flex: 0 0 16.667%;
    max-width: 16.667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .col-lg-5 {
    flex: 0 0 41.667%;
    max-width: 41.667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%; }
  .col-lg-8 {
    flex: 0 0 66.667%;
    max-width: 66.667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%; }
  .col-lg-11 {
    flex: 0 0 91.667%;
    max-width: 91.667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333%; }
  .offset-lg-2 {
    margin-left: 16.667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333%; }
  .offset-lg-5 {
    margin-left: 41.667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333%; }
  .offset-lg-8 {
    margin-left: 66.667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333%; }
  .offset-lg-11 {
    margin-left: 91.667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.667%;
    max-width: 16.667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%; }
  .col-xl-2 {
    flex: 0 0 16.667%;
    max-width: 16.667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .col-xl-5 {
    flex: 0 0 41.667%;
    max-width: 41.667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%; }
  .col-xl-8 {
    flex: 0 0 66.667%;
    max-width: 66.667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%; }
  .col-xl-11 {
    flex: 0 0 91.667%;
    max-width: 91.667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333%; }
  .offset-xl-2 {
    margin-left: 16.667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333%; }
  .offset-xl-5 {
    margin-left: 41.667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333%; }
  .offset-xl-8 {
    margin-left: 66.667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333%; }
  .offset-xl-11 {
    margin-left: 91.667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.313rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.188rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.188rem) right calc(0.375em + 0.188rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.313rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.188rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.188rem) right calc(0.375em + 0.188rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.313rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.563rem;
  padding-left: 0.563rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.857%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

@font-face {
  font-family: 'PF Futura Neu';
  src: url("/assets/fonts/subset-PFFuturaNeu-Bold.eot");
  src: url("/assets/fonts/subset-PFFuturaNeu-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/subset-PFFuturaNeu-Bold.woff2") format("woff2"), url("/assets/fonts/subset-PFFuturaNeu-Bold.woff") format("woff"), url("/assets/fonts/subset-PFFuturaNeu-Bold.ttf") format("truetype"), url("/assets/fonts/subset-PFFuturaNeu-Bold.svg#PFFuturaNeu-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

#languageModal .modal-content {
  border-radius: 0;
  background-color: #00c71f; }

#languageModal .modal-header {
  border-bottom-color: #FFFFFF; }

#languageModal .modal-title {
  font-size: 18px;
  color: #FFFFFF; }

#languageModal .modal-body a, #languageModal .modal-body {
  color: #FFFFFF;
  font-family: 'PF Futura Neu'; }

#languageModal .close {
  color: #FFFFFF;
  text-shadow: unset;
  opacity: 1; }

iframe#map {
  pointer-events: none;
  width: 100%; }

iframe#map.clickable {
  pointer-events: auto; }

.nonsticky-logo {
  display: block; }

.sticky-logo {
  display: none; }

#start-content {
  height: 1px;
  opacity: 0;
  visibility: hidden; }

#start-content.scroll-earlier {
  position: absolute;
  bottom: 300px; }

#navigation, #navigation .navbar-brand img {
  transition: all 0.3s ease-in-out; }

#navigation {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

#navigation.sticky-navigation {
  position: fixed;
  background: transparent; }

.basic-text-component-effect {
  overflow: hidden; }

body.inner-page {
  padding-top: 84px; }

/* TYPOGRAPHY AND GENERAL BODY ELEMENTS */
body {
  font-family: 'Roboto', sans-serif; }

h1, h2, h3, h4, h5, h6, .small-title, .very-small-title, .item .headline, .projects-section .content a, .projects-section .caption .subtitle, .projects-section .caption .title, .projects-section .caption .readmore, .tagline, .btn-text, label, .play, .btn-green, .btn-brown, .btn-black, .inline-words .word, .paginate-projects .left div, .paginate-projects .right div {
  font-family: 'PF Futura Neu'; }

body {
  color: #3C3C3C; }

body, p {
  font-size: 16px;
  line-height: 24px; }

p {
  margin-bottom: 30px; }

h2 {
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 24px; }

h3 {
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 15px; }

h4 {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 5px; }

h5 {
  font-size: 24px;
  line-height: 18px; }

h6 {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 16px; }

.small-title {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px; }

.very-small-title {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 12px; }

h3.with-sub-fix {
  padding-top: 19px; }

.btn-green, .btn-black {
  font-size: 18px;
  line-height: 24px; }

.btn-brown {
  font-size: 14px;
  line-height: 19px; }

.header-navbar .nav-link {
  font-size: 16px;
  font-weight: bold; }

.tagline {
  font-size: 13px; }

.main-btn .btn-text {
  font-size: 14px; }

label {
  font-size: 14px;
  line-height: 19px; }

label.small {
  font-size: 14px;
  line-height: 36px; }

.footer p {
  font-size: 18px;
  line-height: 29px;
  margin-bottom: 34px; }

.footer .mail a, .footer .phone a {
  font-size: 18px;
  line-height: 29px;
  color: #C3D0D2;
  margin-left: 10px; }

.footer .mail a:hover, .footer .phone a:hover {
  color: #FFFFFF; }

.footer a, .footer a svg path {
  transition: all 0.6s ease-in-out; }

.footer a:hover, .footer a:focus, .footer a:active {
  text-decoration: unset; }

.footer .copy {
  font-size: 12px;
  line-height: 24px; }

.footer .pp-link {
  font-size: 12px;
  line-height: 24px; }

.footer .pp-link-with-space {
  margin-right: 15px; }

.footer-navbar a {
  font-size: 14px;
  line-height: 24px; }

.page-header h4 {
  margin-bottom: 94px; }

.inline-words .word {
  font-size: 30px; }

.projects-section .caption .subtitle {
  color: #717E80;
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 5px; }

.projects-section .caption .title {
  /*font-size:24px;*/
  font-size: 20px;
  line-height: 24px;
  /*margin-bottom:23px;*/
  margin-bottom: 10px;
  word-break: break-word; }

.projects-section .caption .readmore {
  font-size: 14px;
  /*line-height:26px;*/
  line-height: 20px;
  /*margin-bottom:14px;*/
  margin-bottom: 10px; }

.projects-section .caption .readmore .arrow-r {
  line-height: 0.1;
  margin-left: 10px; }

.readmore .arrow-r svg {
  width: 8.17px;
  height: 7.69px; }

/*ul, ol {
  padding-left:30px;
}*/
ul li, ol li {
  margin-bottom: 10px; }

ul li.nav-item {
  margin-bottom: 0px; }

ul.pp, ol.pp {
  margin-bottom: 52px;
  padding-left: 45px; }

ul.pp {
  list-style: none;
  /* Remove default bullets */ }

ul.pp li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #00C71F;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */ }

hr.pp {
  margin-top: 41px;
  margin-bottom: 21px; }

.text-body {
  color: #3C3C3C !important; }

.text-lightgray {
  color: #717e80; }

.text-green {
  color: #00C71F; }

.small-title {
  font-weight: bold; }

.very-small-title {
  font-weight: bold; }

.projects-section .caption .subtitle {
  color: #717E80; }

.container.with-tagline {
  position: relative; }

.container.with-tagline:before {
  content: '';
  position: absolute;
  left: calc(-100% + 42px);
  top: 10px;
  height: 2px;
  width: 100%;
  background-color: #00c71f; }

.container.with-tagline.reverse:before {
  background-color: #FFFFFF; }

.tagline {
  margin-left: 42px; }

.with-tagline:before {
  opacity: 0;
  transition: all 0.6s ease-out; }

.intro-section .with-tagline:before {
  opacity: 1; }

.intro-estates a {
  color: #CD7F45; }

.centered-text-component-wrapper {
  background-color: #CD7F45;
  padding-top: 77px;
  padding-bottom: 77px; }

.redirect-wrapper {
  /*background-color:#cd7f45cf;*/
  padding-top: 50px;
  padding-bottom: 50px; }

.intro-estates .estates-text-component {
  padding-top: 0px !important; }

.visible .with-tagline:before {
  opacity: 1; }

.visible.with-tagline:before {
  opacity: 1; }

/* NAVIGATION */
.header-navbar {
  background-color: #FFFFFF; }

.header-navbar.is-transparent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  background-color: transparent; }

.header-navbar .nav-link {
  color: #3C3C3C;
  border: 1px solid transparent;
  position: relative;
  transition: all 0.3s ease-in-out; }

.header-navbar.is-transparent .nav-link {
  color: #FFFFFF; }

.header-navbar.is-transparent .seperator-item .nav-link {
  background-color: #FFFFFF;
  opacity: 0.43;
  height: 22px;
  width: 1px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 20px;
  margin-right: 20px; }

.header-navbar.sticky-navigation .seperator-item .nav-link {
  background-color: #717E80;
  border: unset; }

.header-navbar .seperator-item .nav-link {
  background-color: #717E80;
  opacity: 0.42;
  height: 22px;
  width: 1px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 20px;
  margin-right: 20px;
  border: unset; }

.header-navbar .nav-link.social {
  line-height: 30px; }

.header-navbar .nav-link.main-link:before {
  content: '';
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: -9px;
  background-color: #00C71F;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s ease-in-out; }

.estates-page .header-navbar .nav-link.main-link:before {
  background-color: #cd7f45; }

.header-navbar .nav-link:hover.main-link:before, .header-navbar .nav-link.current.main-link:before {
  opacity: 1; }

.header-navbar .nav-link.estates-link {
  border: 1px solid #00C71F;
  font-weight: 900; }

.header-navbar .nav-link.estates-link:hover {
  background-color: #00C71F;
  color: #3C3C3C; }

.header-navbar .nav-link svg {
  height: 17px; }

.header-navbar .nav-link svg path {
  fill: #3c3c3c; }

.header-navbar.is-transparent .nav-link svg path {
  fill: #FFFFFF; }

.navbar-brand {
  padding: 0px;
  margin: auto; }

.header-navbar .dropdown-menu {
  border-radius: 0;
  top: 80%; }

.header-navbar .dropdown-menu.dropdown-with-lines {
  min-width: 227px;
  padding-top: 25px;
  padding-bottom: 20px; }

.header-navbar .dropdown-menu.dropdown-lang {
  width: 67px;
  min-width: 67px;
  max-width: 67px;
  padding-top: 8px;
  padding-bottom: 4px; }

.header-navbar .dropdown-menu.dropdown-lang .dropdown-item {
  padding-left: 13px; }

.header-navbar .dropdown-with-lines .dropdown-item {
  padding-left: 27px;
  position: relative;
  transition: all .3s ease-in-out;
  color: #717e80; }

.header-navbar .dropdown-with-lines .dropdown-item:hover {
  color: #3c3c3c; }

.header-navbar .dropdown-with-lines .dropdown-item:before {
  content: '';
  display: inline-block;
  background-color: #00c71f;
  transition: all .3s ease-in-out;
  height: 2px;
  width: 0;
  position: absolute;
  left: 0;
  top: 45%; }

.header-navbar .dropdown-menu .dropdown-item:hover, .header-navbar .dropdown-menu .dropdown-item:active {
  background-color: transparent;
  color: #3C3C3C; }

.header-navbar .dropdown-with-lines .dropdown-item:hover, .header-navbar .dropdown-with-lines .dropdown-item:active {
  padding-left: 62px; }

.header-navbar .dropdown-with-lines .dropdown-item:hover:before {
  width: 35px; }

.header-navbar .dropdown-toggle::after {
  content: unset; }

.menu-arrow {
  margin-left: 8px; }

/* FOOTER */
.scroll-to-top {
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 9999; }

.scroll-to-top path, .scroll-to-top line {
  transition: all 0.6s ease-in-out; }

.scroll-to-top:hover path, .scroll-to-top:hover line {
  stroke: #FFFFFF; }

.footer {
  position: relative;
  padding-top: 115px;
  padding-bottom: 50px;
  overflow: hidden;
  background: url("/demo/footer-lines.svg") #3C3C3C;
  background-repeat: no-repeat;
  background-position-x: -200%;
  background-position-y: 100%;
  transition: all 3s ease-in-out; }

.estates-page .footer {
  background: #3c3c3c; }

.footer.visible-lines {
  background-position-x: 0%; }

.footer .divider {
  height: 2px;
  width: 77px;
  background-color: #00C71F;
  margin: auto; }

.footer h5 {
  margin-top: 10px;
  margin-bottom: 29px; }

.footer p {
  color: #FFFFFF; }

.estates-page .footer p {
  color: #B6C6C8; }

.footer .socials {
  padding-top: 135px;
  padding-bottom: 45px; }

.footer .links {
  padding-bottom: 150px; }

.footer .copy {
  color: #717E80; }

.footer .pp-link {
  color: #717E80; }

.footer .pp-link:hover {
  color: #FFFFFF; }

.footer .left, .footer .right, .footer .socials, .footer .links {
  opacity: 0;
  transition: all 0.6s ease-out; }

.footer .left {
  transform: translateX(-100px); }

.footer .right {
  transform: translateX(100px); }

.footer .socials, .footer .links {
  transform: translateY(100px); }

.footer .left.visible, .footer .right.visible, .footer .socials.visible, .footer .links.visible {
  opacity: 1;
  transform: unset; }

.nav-circle .nav-link {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  padding: 0 !important;
  background-color: #00C71F; }

.nav-circle {
  display: flex;
  align-items: center;
  justify-content: center; }

.footer-navbar {
  padding-left: 0px;
  padding-right: 0px; }

.footer-navbar a:hover {
  color: #FFFFFF; }

.footer .socials a:hover path {
  fill: #FFFFFF; }

.footer .socials a {
  padding-left: 20px;
  padding-right: 20px; }

.footer .socials a:first-of-type {
  padding-left: 0px; }

.footer .socials a:last-of-type {
  padding-right: 0px; }

/* GLOBALS */
.fix-md-order {
  order: 2; }

a {
  color: #717E80; }

a:hover {
  color: #717E80; }

a.play:hover, a.main-btn:hover {
  text-decoration: none; }

.fh {
  height: 100vh;
  width: 100%; }

.img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%; }

.pr-normal {
  padding-right: 30px; }

.pl-normal {
  padding-left: 30px; }

.line {
  position: absolute;
  left: 0;
  height: 2px;
  margin-top: 10px;
  width: 52%; }

.super {
  position: relative;
  padding-left: 20px;
  width: 48%;
  margin-left: 51%; }

.outer-ring {
  width: 74px;
  height: 74px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out; }

.middle-ring {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #ffffff54;
  transform: scale(0.89);
  transition: all 0.3s ease-in-out; }

.green .middle-ring {
  border: 1px solid #00C71F54; }

.inner-ring {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /*transform:scale(0.74);*/
  transform: scale(0.702);
  background-color: #FFFFFF;
  transition: all 0.3s ease-in-out; }

.estates-page .inner-ring {
  background-color: #CD7F45; }

.estates-page .middle-ring {
  border: 1px solid #CD7F45; }

.green .inner-ring {
  background-color: #00C71F; }

.main-btn {
  position: relative;
  display: inline-block;
  width: 263px; }

.main-btn:hover .middle-ring {
  transform: scale(1); }

.main-btn:hover .inner-ring {
  transform: scale(0.658); }

.main-btn .btn-text {
  position: absolute;
  left: 32px;
  top: 20px; }

.btn-green:hover, .btn-brown:hover, .btn-black:hover {
  color: #FFFFFF; }

.page-header {
  background-blend-mode: multiply;
  height: 391px; }

.page-header .circle {
  position: absolute;
  left: 47px;
  /* 32px + 15px gutter */
  bottom: 0;
  transform: translateY(50%); }

/*.page-header.about-us-header {
  background:url('/demo/about.jpg'), linear-gradient(to top, #00000073, transparent);
}
.page-header.activities-header {
  background:url('/demo/activities_header.jpg'), linear-gradient(to top, #00000073, transparent);
}
.page-header.projects-header {
  background:url('/demo/projects.jpg'), linear-gradient(to top, #00000073, transparent);
}
.page-header.project-header {
  background:url('/demo/project.jpg'), linear-gradient(to top, #00000073, transparent);
}
.page-header.contact-header {
  background:url('/demo/contact.jpg'), linear-gradient(to top, #00000073, transparent);
}
.page-header.policy-header {
  background:url('/demo/policy.jpg'), linear-gradient(to top, #00000073, transparent);
}*/
.page-content {
  background: url("/demo/page-content-lines.svg");
  background-repeat: no-repeat;
  background-position-x: -400%;
  background-position-y: -35%;
  transition: all 3s ease-in-out; }

.page-content.visible-lines {
  background-position-x: -225%; }

.basic-text-component {
  padding-bottom: 121px; }

.container.basic-text-component.with-tagline:before {
  top: 65px;
  left: -87%;
  height: 4px; }

.basic-text-component-effect .headline, .basic-text-component-effect .paragraph {
  opacity: 0;
  transition: all 0.6s ease-in-out; }

.basic-text-component-effect .headline {
  transform: translateX(-100px); }

.basic-text-component-effect .paragraph {
  transform: translateX(100px); }

.basic-text-component-effect .headline.visible, .basic-text-component-effect .paragraph.visible {
  opacity: 1;
  transform: unset; }

.inline-words .circle {
  width: 10px;
  height: 10px;
  background-color: #00C71F;
  border-radius: 50%;
  margin-left: 35px;
  margin-right: 35px; }

.inline-words .word {
  font-weight: bold; }

.text-image-component {
  padding-bottom: 112px;
  overflow: hidden; }

.text-image-component .text-image-left, .text-image-component .text-image-right {
  opacity: 0;
  transition: all 0.6s ease-in-out; }

.text-image-component .text-image-left {
  transform: translateX(-100px); }

.text-image-component .text-image-right {
  transform: translateX(100px); }

.text-image-component .text-image-left.visible, .text-image-component .text-image-right.visible {
  opacity: 1;
  transform: unset; }

.with-us-component {
  background-color: #F5F5F5;
  margin-bottom: -20px; }

.with-us-component .container {
  max-width: 100%; }

.with-us-component .bg {
  background-color: #FFFFFF;
  height: 133px; }

.with-us-component .caption {
  padding-left: 29px;
  padding-right: 34px;
  background-color: #FFFFFF;
  position: relative; }

.with-us-component .img-col, .with-us-component .caption-col {
  opacity: 0;
  transition: all 0.6s ease-in-out; }

.with-us-component .img-col {
  transform: translateY(100px); }

.with-us-component .caption-col {
  transform: translateY(100px); }

.with-us-component .img-col.visible, .with-us-component .caption-col.visible {
  opacity: 1; }

.with-us-component .caption-col.visible {
  transform: translateY(-45px); }

.with-us-component .img-col.visible {
  transform: translateY(-122px); }

.with-us-component h5 {
  margin-bottom: 21px; }

.with-us-component .caption .btn-green {
  transform: translateY(50%); }

.btn-green {
  background-color: #00C71F;
  border-radius: 0px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 16px;
  padding-bottom: 15px;
  transition: all 0.3s ease-in-out; }

.btn-brown {
  background-color: #CD7F45;
  border-radius: 0px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 16px;
  padding-bottom: 15px; }

.btn-black {
  background-color: #3C3C3C;
  border-radius: 0px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 16px;
  padding-bottom: 15px;
  color: #FFFFFF; }

.btn-black.pdf-link {
  background-color: #cd7f45cf; }

.btn-gray {
  background-color: #F2F5F5; }

.btn-green:hover, .btn-gray:hover {
  text-decoration: unset; }

.btn-gray:hover {
  color: black; }

.error-image svg {
  max-width: 100%;
  height: auto; }

.error-content {
  padding-bottom: 58px; }

.error-content a {
  margin-bottom: 15px; }

.fw-presentation {
  padding-bottom: 101px;
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.6s ease-in-out; }

.fw-presentation.visible {
  opacity: 1;
  transform: unset; }

.fw-presentation .caption {
  position: absolute;
  left: 15px;
  top: 75px;
  background-color: #FFFFFF;
  padding-top: 38px;
  padding-bottom: 52px;
  padding-left: 15px;
  padding-right: 37px;
  z-index: 1; }

.fw-presentation .caption h5 {
  color: #3C3C3C; }

.project-details-component {
  padding-bottom: 90px; }

.project-details-component .left, .project-details-component .right {
  opacity: 0;
  transition: all 0.6s ease-in-out; }

.project-details-component .left {
  transform: translateX(-100px); }

.project-details-component .right {
  transform: translateX(100px); }

.project-details-component .left.visible, .project-details-component .right.visible {
  opacity: 1;
  transform: unset; }

.project-details-component .card-columns {
  column-count: 2;
  orphans: 2;
  widows: 2; }

.project-details-component .card {
  border: unset; }

.item {
  margin-bottom: 33px;
  padding-left: 26px; }

.item .headline {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 3px; }

.item .paragraph {
  font-size: 18px;
  line-height: 36px; }

.project-details .seperator-line {
  content: '';
  background-color: #B7BEBF;
  width: 1px;
  height: 140%;
  position: absolute;
  top: -90px; }

.project-details .line1 {
  left: 0; }

.project-details .line2 {
  left: calc(50% - 7.5px); }

.project-details .line3 {
  right: 0; }

.image-with-caption-component {
  padding-bottom: 90px;
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.6s ease-in-out; }

.image-with-caption-component.visible {
  opacity: 1;
  transform: unset; }

.single-project-wrapper {
  position: relative; }

.single-project-nav {
  background-color: #FFFFFF;
  position: absolute;
  bottom: -57px;
  left: 15px;
  right: 15px;
  width: 164px;
  padding-top: 20px;
  padding-bottom: 35px; }

.single-project-nav .seperator {
  width: 1px;
  height: 42px;
  background-color: #717E8034;
  margin-left: 35px;
  margin-right: 35px; }

.single-project-nav .slick-arrow {
  cursor: pointer; }

.single-project-section {
  opacity: 0;
  transform: translateY(200px);
  transition: all 0.6s ease-in-out; }

.single-project-section.visible {
  opacity: 1;
  transform: unset; }

.single-project-section .spacer {
  height: 100px; }

.single-project-nav .slick-arrow svg path {
  transition: all 0.3s ease-in-out; }

.single-project-nav .slick-arrow:hover svg path {
  fill: #00C71F; }

.single-project-wrapper .fs {
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 1;
  cursor: pointer; }

.single-project-wrapper .fs svg rect {
  transition: all 0.3s ease-in-out; }

.single-project-wrapper .fs:hover svg rect {
  opacity: 1; }

label {
  font-weight: bold; }

label.small {
  color: #717E80;
  font-weight: normal; }

label a {
  color: #3C3C3C; }

label[for="terms"] {
  font-family: 'Roboto', sans-serif; }

label a {
  color: #01c71f;
  transition: all .1s ease-in-out;
  text-decoration: unset !important; }

label a:hover {
  border-bottom: 1px solid #B6C6C8;
  color: #01c71f; }

.estates-page label a {
  color: #3C3C3C; }

.estates-page label a:hover {
  border-bottom: 1px solid #B6C6C8;
  color: #3C3C3C; }

.form-control {
  border-radius: 0;
  border-color: #B6C6C8;
  box-shadow: unset !important;
  height: 55px; }

.form-control:focus, .form-control:active {
  border-color: #3C3C3C; }

.form-control.parsley-error {
  border-color: #C40101; }

label.with-error {
  color: #C40101; }

.parsley-errors-list {
  padding-left: 0px;
  margin-top: 4px;
  display: block;
  list-style-type: none;
  font-size: 14px;
  line-height: 19px;
  font-style: italic;
  color: #C40101; }

.form-group {
  margin-bottom: 21px; }

.form-group.last {
  margin-bottom: 11px; }

/* SECTIONS */
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; }

.slider-wrapper, .slider-wrapper-new {
  position: relative; }

.slider-wrapper .slider *, .slider-wrapper-new .slider * {
  height: 100%;
  width: 100%; }

.slider-wrapper .slick-slide {
  transition: opacity 1s ease-in-out !important; }

.slider img {
  height: 100%;
  object-fit: cover; }

.slider-wrapper .indicators {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 0;
  top: calc(50% - 22px); }

.slider-wrapper .indicators .slick-dots {
  padding: 0px;
  list-style-type: none;
  margin: 0; }

.slider-wrapper .indicators .slick-dots li {
  margin-bottom: 0;
  text-align: right;
  line-height: 0;
  font-size: 0;
  padding-top: 24px;
  padding-bottom: 24px; }

.slider-wrapper .indicators .slick-dots li button {
  height: 2px;
  width: 82px;
  background-color: #16E700;
  text-indent: -9999px;
  border: unset;
  transition: all 0.3s ease-in-out; }

.slider-wrapper .indicators .slick-dots .slick-active button, .slider-wrapper .indicators .slick-dots li:hover button, .slider-wrapper .indicators .slick-dots li button:focus {
  width: 182px;
  outline: unset; }

.slider-wrapper .slick-slide .with-tagline:before {
  opacity: 0;
  transition: all 0.6s ease-in-out; }

.slider-wrapper .slick-current .with-tagline:before {
  opacity: 1; }

.intro-section {
  background: #00000080;
  /*padding-bottom:150px;*/
  padding-top: 365px; }

.intro-section.for-estate {
  padding-top: 290px; }

.estates-page .intro-section.new {
  padding-top: 0px;
  height: 100%; }

.intro-section h4 {
  margin-bottom: 22px; }

.intro-section .line {
  background-color: #00C71F; }

.intro-section .play-circle {
  stroke: #00C71F; }

.intro-section svg {
  overflow: unset; }

.intro-section .play .moving-circle {
  animation: pulseCircle 1s infinite; }

/*.intro-section .play:hover .play-circle {
  animation:pulseCircle 1s infinite;
}*/
.intro-section .play-text {
  margin-left: 12px; }

.about-section {
  background: url("/demo/about-lines.svg") #00C71F;
  background-repeat: no-repeat;
  background-position-x: 200%;
  transition: all 2s ease-in-out;
  /*new*/
  padding-top: 43px;
  padding-bottom: 61px;
  /*end new*/ }

.about-section .line {
  background-color: #FFFFFF; }

.about-section .tagline, .about-section .headline, .about-section .paragraph, .about-section .main-btn {
  opacity: 0;
  transition: all 0.6s ease-out; }

.about-section .tagline {
  transform: translateX(-100px); }

.about-section .headline {
  transform: translateX(100px); }

.about-section .paragraph {
  transform: translateX(-100px); }

.about-section .main-btn {
  transform: translateY(100px); }

.about-section .tagline.visible, .about-section .headline.visible, .about-section .paragraph.visible, .about-section .main-btn.visible {
  opacity: 1;
  transform: none; }

.about-section.visible-lines {
  background-position-x: 100%;
  background-position-y: 40%; }

.activities-section {
  background-color: #00C71F;
  /*height:120vh;*/
  /* new */
  /*height:549px;*/
  height: 700px;
  z-index: 0;
  /* end new*/
  position: relative;
  /*overflow:hidden;*/ }

.activities-section .circle-menu {
  position: absolute;
  left: -1070px;
  top: -420px;
  bottom: 0;
  z-index: 1;
  display: none;
  pointer-events: none; }

.activities-section .circle-menu svg {
  pointer-events: none; }

.activities-section .circle-menu svg circle {
  pointer-events: all; }

.activities-section .circle-menu.circle-menu-desktop {
  display: none; }

.activities-section .circle-menu.circle-menu-tablet {
  left: -528px;
  /*768 - 240 */
  top: 109px;
  display: none; }

.activities-section .circle-menu.circle-menu-mobile {
  /*left:calc(-100% + 68px);
  top:283px;*/
  display: none; }

.activities-section .circle-menu svg {
  width: 1636.3px; }

.activities-section .circle-menu-tablet svg {
  width: 800px; }

.activities-section .circle-menu-mobile svg {
  width: 320px; }

.activities-section svg #circle1, .activities-section svg #circle2, .activities-section svg #circle3, .activities-section svg #circle4, .activities-section svg #circle5 {
  transition: all 0.3s ease-in-out;
  cursor: pointer; }

.activities-section svg #circle1:before, .activities-section svg #circle2:before, .activities-section svg #circle3:before, .activities-section svg #circle4:before, .activities-section svg #circle5:before {
  content: '1';
  color: black;
  position: absolute;
  left: 0;
  top: 0; }

.activities-section svg foreignObject div {
  font: normal normal bold 50px/66px PF Futura Neu;
  letter-spacing: 0px;
  color: #3C3C3C; }

.activities-section .circle-menu-tablet svg foreignObject div {
  font: normal normal bold 24px/30px PF Futura Neu;
  letter-spacing: 0px;
  color: #3C3C3C; }

.activities-section svg foreignObject {
  opacity: 0;
  transition: all 0.3s ease-in-out; }

.activities-section svg circle.current + foreignObject {
  opacity: 1; }

.activities-section .indicators {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 0;
  top: calc(50% - 22px); }

.activities-section .indicators .slick-dots {
  padding: 0px;
  list-style-type: none;
  margin: 0; }

.activities-section .indicators .slick-dots li {
  margin-bottom: 0;
  text-align: right;
  line-height: 0;
  font-size: 0;
  padding-top: 17px;
  padding-bottom: 17px; }

.activities-section .indicators .slick-dots li:before {
  text-indent: unset;
  font: normal normal bold 20px/26px PF Futura Neu;
  letter-spacing: 0px;
  color: #16E700;
  vertical-align: middle;
  margin-right: 10px;
  transition: all 0.3s ease-in-out; }

.activities-section .indicators .slick-dots li.slick-active:before, .activities-section .indicators .slick-dots li:hover:before {
  color: #FFFFFF; }

.activities-section .indicators .slick-dots li:nth-child(1):before {
  content: '01'; }

.activities-section .indicators .slick-dots li:nth-child(2):before {
  content: '02'; }

.activities-section .indicators .slick-dots li:nth-child(3):before {
  content: '03'; }

.activities-section .indicators .slick-dots li:nth-child(4):before {
  content: '04'; }

.activities-section .indicators .slick-dots li:nth-child(5):before {
  content: '05'; }

.activities-section .indicators .slick-dots li button {
  height: 2px;
  width: 82px;
  background-color: #16E700;
  text-indent: -9999px;
  border: unset;
  transition: all 0.3s ease-in-out; }

.activities-section .indicators .slick-dots .slick-active button, .activities-section .indicators .slick-dots li:hover button, .activities-section .indicators .slick-dots li button:focus {
  width: 182px;
  outline: unset; }

.activities-section .indicators.indicators-mb {
  bottom: 0;
  top: unset;
  left: 0;
  right: 0; }

.activities-section .indicators.indicators-mb .slick-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.activities-section .indicators.indicators-mb .slick-dots li button {
  height: 2px;
  width: 20px;
  background: transparent; }

.activities-section .indicators.indicators-mb .slick-dots .slick-active button, .activities-section .indicators.indicators-mb .slick-dots li:hover button, .activities-section .indicators.indicators-mb .slick-dots li button:focus {
  width: 20px; }

.activities-section .indicators.indicators-mb .slick-dots li {
  text-align: center;
  width: 47px; }

.activities-section .indicators.indicators-mb .slick-dots li:before {
  margin-right: unset; }

.activities-section .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0; }

.activities-section .activity {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /*opacity:0;*/
  transition: all 1s ease-out; }

.activities-section .bg-img {
  transition: all 1s ease-out; }

.activities-section .activity.visible {
  opacity: 1; }

.activities-section .indicators {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 32%; }

.activities-section .indicators a {
  padding-top: 11px;
  padding-bottom: 11px; }

.activities-section .indicators a .no {
  font-family: 'PF Futura Neu';
  color: #16E700; }

.activities-section .indicators a .no-line {
  height: 2px;
  width: 82px;
  background-color: #16E700;
  margin-left: 18px;
  transition: all 0.3s ease-in-out; }

.activities-section .indicators a.ind-active .no-line, .activities-section a:hover {
  width: 182px; }

.activities-section .indicators a.ind-active, .activities-section a:hover {
  text-decoration: none; }

.activities-section .indicators a.ind-active .no, .activities-section a:hover .no {
  color: #FFFFFF; }

.activities-section .indicators a.ind-active .no-line, .activities-section a:hover .no-line {
  background-color: #FFFFFF; }

.activities-section .activities-slider .slick-list, .activities-section .activities-slider .slick-track, .activities-section .activities-slider .slick-slide, .activities-section .activities-slider .slick-slide > div, .activities-section .activities-slider .slick-slide > div > div {
  height: 100%; }

/*.activities-section .indicators ul {
  padding: 0px;
  list-style-type: none;
  margin: 0;
}*/
/*.activities-section .indicators ul li {
  margin-bottom:0;
  text-align:right;
  line-height:1;
  padding-top:24px;
  padding-bottom:24px;
}*/
/*.activities-section .indicators a {
  height:2px;
  width:82px;
  background-color:#FFFFFF;
  border:unset;
  display:block;
  transition:all 0.3s ease-in-out;
}*/
.projects-section {
  padding-bottom: 88px; }

.home-projects-section {
  /*background:url('/demo/page-content-lines.svg'), linear-gradient(#00c71f 0%, #00c71f 220px, transparent 220px, transparent 100%);*/
  /* new*/
  background: url("/demo/page-content-lines.svg"), transparent;
  /*end new*/
  background-repeat: no-repeat;
  background-position-x: -400%;
  background-position-y: -35%;
  transition: all 0.6s ease-in-out; }

.home-projects-section.visible-lines {
  background-position-x: -225%; }

.projects-section .card-columns {
  column-count: 2; }

.projects-section .card {
  border-color: transparent;
  border-radius: 0;
  background-color: transparent; }

.projects-section .single {
  position: relative; }

.projects-section .type3 {
  text-align: right; }

.projects-section .type1, .projects-section .type2, .projects-section .type3, .projects-section .content {
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.6s ease-out; }

.projects-section .type2 {
  transform: translateY(410px); }

.projects-section .type3 {
  transform: translateY(240px); }

.projects-section .content {
  transform: translateY(430px); }

.projects-section .type1.visible, .projects-section .type2.visible, .projects-section .type3.visible, .projects-section .content.visible {
  opacity: 1;
  transform: none; }

.projects-section .type2.visible {
  transform: translateY(310px); }

.projects-section .type3.visible {
  transform: translateY(140px); }

.projects-section .content.visible {
  transform: translateY(330px); }

.projects-section .caption .subtitle {
  color: #717E80;
  transition: all 0.3s ease-in-out; }

.projects-section .caption .title, .projects-section .caption .readmore {
  color: #3C3C3C;
  transition: all 0.3s ease-in-out; }

.projects-section .caption {
  background-color: #FFFFFF;
  position: absolute;
  right: 0;
  bottom: 0px;
  width: 306px;
  height: 215px;
  padding-left: 32px;
  padding-right: 32px;
  overflow: hidden; }

.home-projects-section .caption {
  height: 263px; }

.projects-section .project-presentation {
  padding-bottom: 30px; }

.home-projects-section .caption {
  bottom: 60px; }

.projects-section .caption-content {
  position: relative;
  width: 100%;
  height: 100%; }

.projects-section .caption .bg {
  background-color: #00C71F;
  border-radius: 50%;
  position: absolute;
  width: 70px;
  height: 70px;
  left: -14%;
  top: -14%;
  transition: all 0.3s ease-in-out; }

.inner-projects .single {
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.6s ease-in-out; }

.inner-projects .single.visible {
  opacity: 1;
  transform: unset; }

.projects-section .single .caption:hover .bg {
  width: 160%;
  height: 160%;
  left: -25%;
  top: -25%; }

.inner-projects .single .caption:hover .bg {
  height: 227.7%;
  top: -60%; }

.projects-section .single .caption:hover .subtitle, .projects-section .single .caption:hover .readmore {
  color: #FFFFFF; }

.projects-section .content {
  padding-left: 50px; }

.projects-section .content h3 {
  margin-bottom: 55px; }

.projects-section .content a {
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 25px; }

.projects-section .content a:hover, .projects-section .content a:focus {
  text-decoration: none; }

.projects-section .content a:before {
  content: '';
  display: inline-block;
  vertical-align: 9px;
  background-color: #00C71F;
  transition: all 0.3s ease-in-out;
  height: 2px;
  width: 17px;
  margin-right: 10px; }

.projects-section .content a.extra:before {
  content: unset; }

.projects-section .content a:hover:before {
  width: 70px;
  margin-right: 20px; }

.projects-section .content a.extra .middle-ring {
  border-color: #00C71F54; }

.projects-section .content a.extra .inner-ring {
  background-color: #00C71F; }

.projects-section .content a.extra.main-btn .btn-text {
  top: 24px; }

.estates-section-wrapper {
  position: relative;
  overflow-x: hidden;
  padding-top: 200px; }

.estates-section {
  background-color: #b6c6c8;
  height: auto;
  padding-top: 200px;
  padding-bottom: 173px; }

.estates-section .estates-logo {
  margin-top: 47px; }

.estates-section .tagline, .estates-section .headline, .estates-section .paragraph, .estates-section .main-btn, .estates-logo {
  opacity: 0;
  transition: all 0.6s ease-out; }

.estates-section .tagline {
  transform: translateX(-100px); }

.estates-section .headline {
  transform: translateX(100px); }

.estates-section .paragraph {
  transform: translateX(-100px); }

.estates-section .main-btn {
  transform: translateY(100px); }

.estates-section .tagline.visible, .estates-section .headline.visible, .estates-section .paragraph.visible, .estates-section .main-btn.visible, .estates-section .estates-logo.visible {
  opacity: 1;
  transform: none; }

.estates-section-wrapper .bg-img {
  position: absolute;
  top: 0; }

.contact-form-section {
  padding-bottom: 144px; }

.contact-form-section .headline, .contact-form-section .paragraph, .contact-form-section .form {
  opacity: 0;
  transition: all 0.6s ease-in-out; }

.contact-form-section .headline {
  transform: translateX(-200px); }

.contact-form-section .paragraph {
  transform: translateX(-200px); }

.contact-form-section .form {
  transform: translateY(200px); }

.contact-form-section .headline.visible, .contact-form-section .paragraph.visible, .contact-form-section .form.visible {
  opacity: 1;
  transform: unset; }

.find-us-section {
  background-color: #F5F5F5;
  padding-bottom: 140px;
  padding-top: 108px; }

.find-us-section .placeimg {
  padding-bottom: 18px; }

.policy-section {
  padding-bottom: 157px; }

/*h4(h6), hr(pp), h5(h6 small title), h6(h6 very small title), ul(pp), ol(pp)*/
.policy-section h4 {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 16px; }

.policy-section hr {
  margin-top: 41px;
  margin-bottom: 21px; }

.policy-section h5 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px; }

.policy-section h6 {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 12px; }

.policy-section ul {
  list-style: none; }

.policy-section ul, .policy-section ol {
  margin-bottom: 52px;
  padding-left: 45px; }

.policy-section ul {
  list-style: none;
  /* Remove default bullets */ }

.policy-section ul li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #00C71F;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */ }

/* MFP */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out; }

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8; }

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0; }

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.3s ease-out; }

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0; }

.with-tagline.has-effect:before {
  transform: translateX(-100px); }

.visible .with-tagline.has-effect:before {
  transform: unset; }

.visible.with-tagline.has-effect:before {
  transform: unset; }

.residence-page #navigation .navbar-brand .nonsticky-logo {
  max-width: 38px; }

.residence-page #navigation .navbar-brand .sticky-logo {
  max-height: 50px; }

.estates-page .basic-text-component .left-img {
  max-width: 131px; }

@media (min-width: 768px) {
  .residence-page #navigation .navbar-brand .nonsticky-logo {
    max-width: 67px; }
  .centered-text-component-wrapper {
    padding-top: 103px;
    padding-bottom: 99px; }
  .pt-custom {
    padding-top: 64px; }
  .activities-section .circle-menu.circle-menu-mobile {
    display: none; }
  .activities-section .circle-menu.circle-menu-tablet {
    display: block; }
  body.inner-page {
    padding-top: 117px; }
  .header-navbar {
    padding-left: 75px;
    padding-right: 75px;
    padding-top: 50px;
    padding-bottom: 45px; }
  .header-navbar .dropdown-with-lines.show {
    animation-name: slidenavAnimation;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards; }
  @keyframes slidenavAnimation {
    from {
      opacity: 0;
      /*left:100%;*/ }
    to {
      opacity: 1;
      /*left:0;*/ } }
  body, p {
    font-size: 16px;
    line-height: 24px; }
  p {
    margin-bottom: 30px; }
  h2 {
    font-size: 72px;
    line-height: 80px;
    margin-bottom: 18px; }
  h3 {
    font-size: 36px;
    line-height: 42px; }
  h4 {
    font-size: 60px;
    line-height: 65px;
    margin-bottom: 9px; }
  h5 {
    font-size: 24px;
    line-height: 32px; }
  h6 {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 22px; }
  .small-title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 14px; }
  .very-small-title {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px; }
  .tagline {
    margin-left: 38px; }
  .page-header {
    margin-bottom: 90px;
    height: 720px; }
  .page-header h4 {
    margin-bottom: 90px; }
  .intro-section {
    padding-top: 589px; }
  .intro-section.for-estate {
    padding-top: 670px; }
  .estates-page .intro-section.new {
    padding-top: 0px; }
  .about-section {
    /*new*/
    background: url("/demo/about-lines-tablet.svg") #00C71F;
    background-repeat: no-repeat;
    background-position-x: 200%;
    padding-top: 260px;
    padding-bottom: 280px;
    /*end new*/ }
  .activities-section {
    /* new */
    height: 1024px;
    /* end new */ }
  .activities-section .bg-img {
    /*left:-220px;*/
    margin: auto;
    left: 0;
    max-width: 170px; }
  .home-projects-section {
    padding-bottom: 60px;
    /*new*/
    transform: translateY(-125px);
    /* end new*/ }
  .projects-section .caption .subtitle {
    font-size: 11px;
    line-height: 22px; }
  .projects-section .caption .title {
    /*font-size:18px;*/
    /*line-height:18px;*/
    /*margin-bottom:16px;*/
    font-size: 17px;
    line-height: 17px; }
  .projects-section .caption .readmore {
    font-size: 11px;
    /*line-height:36px;*/
    /*margin-bottom:10px;*/ }
  .projects-section .caption {
    /*width:172px;*/
    /*height:142px;*/
    width: 184px;
    height: 140px;
    bottom: 22px; }
  .projects-section .caption.caption-bottom {
    bottom: 0px; }
  .home-projects-section .caption {
    height: 147px;
    bottom: 0px; }
  .projects-section .caption .bg {
    left: -26%;
    top: -26%; }
  .home-projects-section {
    /*background:url('/demo/page-content-lines.svg'), linear-gradient(#00c71f 0%, #00c71f 120px, transparent 120px, transparent 100%);*/
    /* new */
    background: url("/demo/page-content-lines.svg"), transparent;
    /* end new */
    background-repeat: no-repeat;
    background-position-x: -400%;
    background-position-y: -35%; }
  .projects-section .type2 {
    transform: translateY(340px); }
  .projects-section .type3 {
    transform: translateY(170px); }
  .projects-section .content {
    transform: translateY(360px); }
  .projects-section .type2.visible {
    transform: translateY(170px); }
  .projects-section .type3.visible {
    transform: translateY(50px); }
  .projects-section .content.visible {
    transform: translateY(190px); }
  .estates-section-wrapper .bg-img {
    bottom: 45%;
    right: 0;
    max-width: 585px; }
  .estates-section .estates-logo {
    max-width: 352px; }
  .basic-text-component {
    padding-bottom: 35px; }
  .basic-text-component.with-extra-padding {
    padding-bottom: 93px; }
  .basic-text-component.with-tagline h6 {
    margin-bottom: 50px; }
  .text-image-component {
    padding-bottom: 69px;
    margin: auto; }
  .container, .container-md, .container-sm, .text-image-component {
    max-width: 630px; }
  .with-us-component .caption {
    padding-top: 0px; }
  .fw-presentation {
    padding-bottom: 165px; }
  .fw-presentation .caption {
    top: 25px; }
  .project-details .row .col-md-6 {
    padding-left: 0px;
    padding-right: 0px; }
  .image-with-caption-component p {
    margin-top: 23px; }
  .projects-section {
    padding-bottom: 72px; }
  .image-with-caption-component {
    padding-bottom: 51px; }
  .policy-section {
    padding-bottom: 119px; }
  .with-us-component .img-col {
    transform: translateY(100px); }
  .with-us-component .caption-col {
    transform: translateY(100px); }
  .with-us-component .caption-col.visible {
    transform: translateY(-72px); }
  .with-us-component .img-col.visible {
    transform: translateY(-72px); }
  .container.with-tagline:before {
    left: calc(-100% + 38px); }
  .container.basic-text-component.with-tagline:before {
    top: 65px;
    left: -87%; }
  .paginate-projects {
    /*padding-bottom:43px;*/
    border: 1px solid #717E80;
    margin-bottom: 43px; }
  .paginate-projects .left, .paginate-projects .right {
    width: calc(50% - 0.5px);
    padding-top: 27px;
    padding-bottom: 27px;
    cursor: pointer; }
  .paginate-projects a {
    text-decoration: none; }
  .paginate-projects .left div, .paginate-projects .right div, .paginate-projects .left svg path, .paginate-projects .right svg path {
    transition: all 0.3s ease-in-out; }
  .paginate-projects .left:hover div, .paginate-projects .right:hover div {
    color: #00C71F; }
  .paginate-projects .left:hover svg path, .paginate-projects .right:hover svg path {
    fill: #00C71F; }
  .paginate-projects .left div, .paginate-projects .right div {
    font-size: 18px;
    line-height: 24px; }
  .paginate-projects .left {
    padding-left: 26px; }
  .paginate-projects .right {
    padding-right: 26px; }
  .paginate-projects .left div {
    margin-left: 15%; }
  .paginate-projects .right div {
    margin-right: 15%; }
  .paginate-seperator {
    height: auto;
    width: 1px;
    background-color: #707070;
    opacity: 0.34; }
  .policy-section h4 {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 22px; }
  .policy-section h5 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 14px; }
  .policy-section h6 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px; }
  .footer .pp-link-with-space {
    margin-right: 35px; } }

@media (min-width: 992px) {
  .residence-page #navigation .navbar-brand .nonsticky-logo {
    max-width: 127px; }
  .centered-text-component-wrapper {
    padding-top: 153px;
    padding-bottom: 150px; }
  /*  body.inner-page {
      padding-top:170px;
    }*/
  /*  #navigation.sticky-navigation {
      padding-top: 20px;
      padding-bottom: 25px;
      background:#FFFFFF;
      border-bottom:1px solid #717e80;
    }
    #navigation .navbar-brand img {
      max-width:278px;
    }
    #navigation.sticky-navigation .navbar-brand img {
      max-width: 150px;
      transition:all 0.3s ease-in-out;
    }
    #navigation.sticky-navigation .nav-link {
      color:#3c3c3c;
    }
    #navigation.sticky-navigation .nav-link svg path {
      fill:rgb(113, 126, 128);
    }
    .header-navbar, .header-navbar.is-transparent {
      background-position: 120% 150%;
    }*/
  body, p {
    font-size: 20px;
    line-height: 34px; }
  p {
    margin-bottom: 33px; }
  h2 {
    /*font-size:100px;*/
    /*line-height:100px;*/
    font-size: 85px;
    line-height: 85px;
    margin-bottom: 10px;
    /*margin-bottom:60px;*/ }
  h3 {
    font-size: 90px;
    line-height: 120px; }
  .activities-section h3 {
    line-height: 60px;
    font-size: 75px; }
  h4 {
    font-size: 72px;
    line-height: 80px;
    margin-bottom: 5px; }
  h5 {
    font-size: 36px;
    line-height: 48px; }
  h6 {
    font-size: 60px;
    line-height: 80px;
    margin-bottom: 41px; }
  .small-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px; }
  .very-small-title {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px; }
  h3.with-sub-fix {
    /*padding-top:45px;*/
    padding-top: 70px; }
  .btn-green, .btn-brown, .btn-black {
    font-size: 18px;
    line-height: 24px; }
  .header-navbar .nav-link {
    font-size: 16px;
    font-weight: bold; }
  .tagline {
    font-size: 18px;
    margin-left: 42px; }
  .main-btn .btn-text {
    font-size: 16px; }
  label {
    font-size: 18px;
    line-height: 24px; }
  label.small {
    font-size: 14px;
    line-height: 36px; }
  .footer p {
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 34px; }
  .footer .mail a, .footer .phone a {
    font-size: 18px;
    line-height: 29px; }
  .footer .copy {
    font-size: 12px;
    line-height: 24px; }
  .footer .pp-link {
    font-size: 12px;
    line-height: 24px; }
  .footer-navbar a {
    font-size: 14px;
    line-height: 24px; }
  .page-header h4 {
    margin-bottom: 94px; }
  .inline-words .word {
    font-size: 30px; }
  .projects-section .caption .subtitle {
    color: #717E80;
    font-size: 16px;
    line-height: 22px; }
  .projects-section .caption .title {
    /*font-size:32px;*/
    /*font-size:24px;*/
    /*line-height:34px;*/
    font-size: 20px;
    /*line-height:30px;*/
    line-height: 24px;
    margin-bottom: 10px; }
  .home-projects-section .caption .title {
    font-size: 32px;
    line-height: 34px; }
  .projects-section .caption .readmore {
    /*font-size:16px;*/
    font-size: 13px;
    /*line-height:36px;*/
    /*margin-bottom:18px;*/
    /*margin-bottom:10px;*/ }
  .home-projects-section.caption .readmore {
    font-size: 16px;
    margin-bottom: 18px; }
  .header-navbar {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 41px;
    padding-bottom: 47px; }
  .header-navbar .navbar-nav .nav-link {
    padding-top: 8px;
    padding-bottom: 5px; }
  .header-navbar .navbar-nav .nav-link.main-link, .header-navbar .navbar-nav .nav-link.estates-link {
    padding-left: 22px;
    padding-right: 22px; }
  .footer-navbar .navbar-nav .nav-link, .footer-navbar .navbar-nav .nav-circle {
    padding-left: 12px;
    padding-right: 12px; }
  .projects-section .caption {
    width: 306px;
    /*height:215px;*/
    height: 145px;
    bottom: 0px;
    padding-left: 25px;
    padding-right: 25px; }
  .projects-section .caption-content {
    padding-bottom: 5px; }
  .projects-section .caption.caption-bottom {
    bottom: 0px; }
  .home-projects-section .caption {
    height: 263px;
    bottom: 61px; }
  .projects-section .caption .bg {
    left: -14%;
    top: -14%; }
  .home-projects-section {
    /*background:url('/demo/page-content-lines.svg'), linear-gradient(#00c71f 0%, #00c71f 220px, transparent 220px, transparent 100%);*/
    /* new */
    background: url("/demo/page-content-lines.svg"), transparent;
    /* end new */
    background-repeat: no-repeat;
    background-position-x: -400%;
    background-position-y: -35%; }
  .projects-section .type2 {
    transform: translateY(410px); }
  .projects-section .type3 {
    transform: translateY(240px); }
  .projects-section .content {
    transform: translate(100px, 330px); }
  .projects-section .type2.visible {
    transform: translateY(310px); }
  .projects-section .type3.visible {
    transform: translateY(140px); }
  .projects-section .content.visible {
    transform: translate(0, 330px); }
  .estates-section .estates-logo {
    max-width: 100%; }
  .basic-text-component {
    padding-bottom: 121px; }
  .basic-text-component.with-extra-padding {
    padding-bottom: 121px; }
  .pr-lg-normal {
    padding-right: 30px; }
  .pl-lg-normal {
    padding-left: 30px; }
  .text-image-component {
    padding-bottom: 112px; }
  .text-image-component {
    max-width: 100%; }
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px; }
  .with-us-component .container {
    max-width: 960px; }
  .fix-md-order {
    order: unset; }
  .with-us-component .caption {
    padding-top: 0px; }
  .fw-presentation {
    padding-bottom: 101px; }
  .fw-presentation .caption {
    top: 75px; }
  .project-details .row .col-md-6 {
    padding-left: 15px;
    padding-right: 15px; }
  .image-with-caption-component p {
    margin-top: 43px; }
  .projects-section {
    padding-bottom: 88px; }
  .home-projects-section {
    /*padding-top:100px;*/
    padding-bottom: 330px;
    overflow: hidden; }
  .image-with-caption-component {
    padding-bottom: 90px; }
  .policy-section {
    padding-bottom: 157px; }
  .with-us-component .img-col {
    transform: translateY(100px); }
  .with-us-component .caption-col {
    transform: translateY(100px); }
  .with-us-component .caption-col.visible {
    transform: translateY(-45px); }
  .with-us-component .img-col.visible {
    transform: translateY(-122px); }
  .container.with-tagline:before {
    top: 15px;
    left: calc(-100% + 42px); }
  .container.with-tagline.offset1:before {
    left: calc(-100% + 42px + 8.333%); }
  .container.with-tagline.offset2:before {
    left: calc(-100% + 42px + 16.667%); }
  .container.basic-text-component.with-tagline:before {
    top: 105px;
    left: -93%; }
  /*  .activities-section .circle-menu {
      left:-1325px;
    }*/
  .activities-section .indicators {
    /*top:70%;*/
    top: calc(50% - 22px);
    transform: translateY(-50%); }
  /*  .activities-section .indicators .slick-dots li button, .activities-section .indicators .slick-dots .slick-active button, .activities-section .indicators .slick-dots li button:focus, .activities-section .indicators .slick-dots li:hover button {
      width:20px;
    }*/
  .scroll-to-top {
    bottom: 130px;
    right: 95px; }
  .dropdown:hover > .dropdown-menu {
    display: block;
    animation-name: slidenavAnimation;
    animation-duration: .4s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards; }
  .paginate-projects .left div {
    margin-left: 29.5%; }
  .paginate-projects .right div {
    margin-right: 29.5%; }
  .policy-section h4 {
    font-size: 60px;
    line-height: 80px;
    margin-bottom: 41px; }
  .policy-section h5 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px; }
  .policy-section h6 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px; }
  .btn-brown {
    font-size: 18px;
    line-height: 24px; } }

@media (min-width: 1024px) {
  h3 span {
    line-height: 50px; }
  .headline span {
    line-height: 100px; } }

@media (min-width: 1200px) {
  .activities-section .circle-menu.circle-menu-tablet {
    display: none; }
  .activities-section .circle-menu.circle-menu-desktop {
    display: block; }
  .activities-section .bg-img {
    left: -220px;
    max-width: unset; }
  .d-lg-none-custom {
    display: none !important; }
  .lg-hide {
    display: none; }
  .d-lg-flex-custom {
    display: flex; }
  .pt-custom {
    padding-top: 113px; }
  .container, .container-lg, .container-md, .container-sm, .container-xl, .with-us-component .container {
    max-width: 1140px; }
  .intro-section {
    padding-top: 25%; }
  .intro-section.for-estate {
    padding-top: 30%; }
  .estates-page .intro-section.new {
    padding-top: 0px; }
  .about-section {
    /*new*/
    background: url("/demo/about-lines.svg") #00C71F;
    background-repeat: no-repeat;
    background-position-x: 200%;
    padding-top: 191px;
    padding-bottom: 164px;
    /*end new*/ }
  .activities-section {
    /* new */
    height: 1311px;
    /* end new */ }
  .activities-section .bg-img {
    left: 0;
    margin: unset; }
  body.inner-page {
    padding-top: 170px; }
  #navigation.sticky-navigation {
    padding-top: 20px;
    padding-bottom: 25px;
    background: #FFFFFF;
    border-bottom: 1px solid #717e80; }
  .nonsticky-logo {
    display: block; }
  .sticky-logo {
    display: block; }
  #navigation .nonsticky-logo {
    opacity: 1;
    visibility: visible;
    width: 100%;
    height: auto;
    transition: all 0.3s ease-in-out; }
  #navigation .sticky-logo {
    opacity: 0;
    visibility: hidden;
    width: 0px;
    height: 0px;
    transition: all 0.3s ease-in-out; }
  #navigation.sticky-navigation .nonsticky-logo {
    visibility: hidden;
    opacity: 0;
    width: 0px;
    height: 0px; }
  #navigation.sticky-navigation .sticky-logo {
    visibility: visible;
    opacity: 1;
    width: 100%;
    height: auto; }
  #navigation .navbar-brand img {
    max-width: 277px; }
  .residence-page #navigation .navbar-brand .nonsticky-logo {
    max-width: 127px; }
  .residence-page #navigation {
    align-items: flex-start; }
  #navigation.sticky-navigation .navbar-brand img {
    max-width: 150px;
    transition: all 0.3s ease-in-out; }
  #navigation.sticky-navigation .nav-link {
    color: #3c3c3c;
    font-size: 15px; }
  #navigation.sticky-navigation .nav-link svg path {
    fill: #717e80; }
  .header-navbar, .header-navbar.is-transparent {
    background-position: 120% 150%; }
  /* start george css */
  .projects-section .caption {
    width: 280px;
    /*height: 180px;*/
    height: 160px; }
  .home-projects-section .caption {
    width: 300px;
    height: 205px; }
  .projects-section .caption .readmore {
    margin-bottom: 0px;
    line-height: 28px; }
  .projects-section .caption .title {
    /*font-size: 22px;*/
    /*line-height: 28px;*/
    font-size: 18px;
    line-height: 20px; }
  .home-projects-section .caption .title {
    /*font-size: 28px;
    line-height: 32px*/
    font-size: 26px;
    line-height: 30px; }
  .inner-page .basic-text-component-effect {
    overflow: unset; }
  .fw-presentation .caption {
    min-width: 250px; } }

@media (min-width: 1200px) and (max-width: 1300px) {
  .activities-section .offset-lg-5 {
    margin-left: 45%; } }

@media (min-width: 1365px) {
  h2 {
    margin-bottom: 60px; } }

@media (min-width: 1400px) {
  .header-navbar {
    padding-left: 60px;
    padding-right: 60px; } }

@media (min-width: 1600px) {
  .activities-section h3 {
    /*font-size:90px;*/ }
  .estates-section-wrapper .bg-img {
    right: 0;
    max-width: unset; } }

@media (min-width: 1840px) {
  .activities-section .bg-img {
    max-width: unset;
    left: 0px;
    margin: unset; }
  .activities-section .indicators {
    top: 32%;
    transform: unset; }
  .activities-section .indicators .slick-dots li button {
    width: 82px; }
  .activities-section .indicators .slick-dots .slick-active button, .activities-section .indicators .slick-dots li button:focus, .activities-section .indicators .slick-dots li:hover button {
    width: 182px; }
  .activities-section .circle-menu {
    left: -1070px;
    top: -420px; } }

@media (max-width: 1199px) {
  .menu-active .page-header, .menu-active .footer, .menu-active .page-content, .menu-active .slider-wrapper, .menu-active .about-section, .menu-active .activities-section, .menu-active .home-projects-section, .menu-active .estates-section {
    opacity: 0.6; }
  .footer {
    z-index: 1; }
  #navigation.sticky-navigation .navbar-brand img {
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  .header-navbar {
    /*    background:url('/demo/burger-bg.svg') #FFFFFF;
        background-repeat:no-repeat;
        background-position: 120% 150%;*/
    z-index: 1;
    padding-top: 16px;
    padding-bottom: 19px; }
  .header-navbar.is-transparent {
    /*    background:url('/demo/burger-bg.svg') transparent;
        background-repeat:no-repeat;
        background-position: 120% 150%;*/ }
  .header-navbar .nav-link {
    color: #FFFFFF; }
  .header-navbar .nav-link svg path {
    fill: #FFFFFF; }
  .navbar-toggler {
    z-index: 1;
    position: absolute;
    top: 15px;
    right: 20px;
    padding: 0;
    border: unset;
    /*overflow:hidden;*/ }
  .navbar-toggler:focus {
    outline: unset; }
  .burger-bg {
    /*    position:absolute;
        top:0;
        right:0;
        transform:translate(45%, -45%);
        overflow:hidden;*/
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    width: 120px;
    height: 120px; }
  .burger-bg svg {
    position: absolute;
    top: -50px;
    right: -50px;
    overflow: hidden; }
  .burger-bg svg circle {
    overflow: hidden; }
  .navbar-collapse {
    background-color: #00C71F;
    min-height: 100vh;
    position: absolute;
    /*width:81.25%;*/
    width: 0;
    /*left:100%;*/
    margin-left: 18.75%;
    right: 0;
    top: 0;
    transition: all 0.35s ease; }
  .navbar-collapse.show {
    /*left:18.75%;*/
    width: 81.25%; }
  .navbar-collapse .navbar-nav {
    padding-left: 15px;
    padding-top: 58px; }
  .navbar-collapse .dropdown-toggle {
    align-items: center; }
  .seperator-item {
    display: none; }
  .mb-hide {
    display: none; }
  .mb-ul-nav {
    list-style-type: none;
    padding-left: 15px;
    margin-bottom: 0px; }
  .navbar-nav .nav-link {
    display: inline-flex; }
  .navbar-nav .nav-link {
    padding-left: 15px;
    padding-right: 15px; }
  .mb-ul-nav li {
    margin-right: 34px;
    margin-bottom: 0px; }
  .mb-ul-nav.social-nav li {
    margin-right: 29px; }
  .header-navbar .nav-link.estates-link {
    border-color: #FFFFFF45; }
  .header-navbar .lang-nav {
    border-top: 1px solid #FFFFFF45;
    border-bottom: 1px solid #FFFFFF45;
    padding-top: 0px;
    padding-bottom: 0px; }
  .header-navbar .social-nav {
    padding-top: 0px; }
  .header-navbar .dropdown-menu.dropdown-with-lines {
    max-width: 100%;
    background: transparent;
    border: unset;
    box-shadow: unset !important;
    padding-top: 0px;
    margin-top: -10px;
    padding-bottom: 19px; }
  .header-navbar .dropdown-with-lines .dropdown-item:before {
    background-color: #FFFFFF;
    width: 35px !important;
    left: -15px !important; }
  .header-navbar .dropdown-with-lines .dropdown-item {
    padding-left: 27px;
    padding-top: 14px;
    padding-bottom: 14px;
    color: #FFFFFF; } }

@media (max-width: 1365px) {
  /*  .activities-section {
      overflow:hidden;
    }*/ }

@media (min-width: 992px) and (max-width: 1119px) {
  .header-navbar {
    background-position: 109% 2500%; }
  .header-navbar.is-transparent {
    background-position: 109% 2500%; } }

@media (max-width: 767px) {
  .estate-details-wrapper .left {
    order: 2; }
  .estate-details-wrapper img {
    margin-top: 22px; }
  .mb-ul-nav.social-nav li, .mb-ul-nav li {
    margin-right: 0; }
  .navbar-nav .nav-link {
    padding-top: 17px;
    padding-bottom: 17px; }
  .header-navbar .lang-nav {
    margin-top: 17px; }
  .home-projects-section .content {
    opacity: 1;
    transform: unset; }
  .activities-section p.text-white {
    padding-left: 0px;
    font-size: 14px; }
  .activities-section .main-btn {
    margin-left: 80px;
    width: auto; }
  .activities-section .circle-menu {
    top: -450px;
    left: -1565px; }
  .estates-section-wrapper .bg-img {
    max-width: 160px;
    top: 0; }
  .estates-section {
    padding-bottom: 30px;
    overflow: hidden; }
  .estates-mobile-logo {
    position: absolute;
    top: 150px;
    left: 0;
    right: 0; }
  /*  .intro-section {
    padding-bottom:100px;
  }*/
  .intro-section h4 {
    margin-bottom: 12px; }
  .play svg {
    max-width: 54px; }
  h3 span {
    margin-top: unset; }
  .projects-section .content {
    padding-left: 15px; }
  .navbar-brand img {
    max-width: 167px; }
  .header-navbar {
    padding-left: 15px;
    padding-right: 15px; }
  .activity-content {
    /*padding-left:75px;*/
    padding-left: 15px;
    padding-right: 15px; }
  .about-section {
    overflow: hidden;
    background: #00c71f; }
  .main-btn .btn-text {
    top: 25px; }
  p.text-white {
    margin-bottom: 10px; }
  .home-projects-section .caption {
    width: 230px;
    height: 198px;
    bottom: 32px; }
  .home-projects-section {
    /*background:url('/demo/page-content-lines.svg'),linear-gradient(#00c71f 0,#00c71f 90px,transparent 90px,transparent 100%);*/
    background: linear-gradient(to bottom, #01c71f 90px, transparent 91px); }
  .projects-carousel .slick-dots {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0;
    padding-top: 20px; }
  .projects-carousel .slick-dots li {
    margin-bottom: 0px; }
  .projects-carousel .slick-dots button {
    text-indent: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #717E80;
    padding: 0;
    margin-left: 15px;
    margin-right: 15px;
    border-color: transparent; }
  .projects-carousel .slick-dots li.slick-active button, .projects-carousel .slick-dots li:hover button {
    background: #B6C6C8; }
  .projects-carousel .slick-dots li button:focus {
    outline: unset; }
  .estates-section-wrapper .bg-img {
    right: 0px; }
  .estates-section-wrapper {
    padding-top: 75px; }
  .footer {
    padding-top: 37px;
    padding-bottom: 30px;
    background: #3c3c3c; }
  .footer .left {
    padding-bottom: 58px; }
  .footer .socials {
    padding-top: 53px;
    padding-bottom: 30px; }
  .footer .copycol {
    /*padding-bottom:14px;*/ }
  .footer svg {
    max-height: 18px; }
  .footer h5 {
    margin-top: 20px; }
  .page-header {
    margin-bottom: 60px; }
  .page-header h4 {
    margin-bottom: 52px; }
  .page-header .circle {
    left: 15px; }
  .page-header .circle svg {
    width: 72px;
    height: 72px; }
  .inline-words {
    flex-direction: column; }
  .inline-words .circle {
    margin-top: 26px;
    margin-bottom: 22px; }
  .basic-text-component {
    padding-bottom: 40px;
    overflow: hidden; }
  .basic-text-component:last-of-type {
    padding-bottom: 75px; }
  .basic-text-component.estates-text-component:last-of-type {
    padding-bottom: 20px; }
  .basic-text-component h6 {
    margin-bottom: 38px; }
  .basic-text-component h6.small-title {
    margin-bottom: 11px; }
  .container.basic-text-component.with-tagline:before {
    top: 50px; }
  .container.basic-text-component.with-tagline:before {
    left: -76%; }
  .text-image-component {
    padding-bottom: 54px; }
  .with-us-component .caption {
    text-align: center;
    padding-top: 32px; }
  .with-us-component .caption h5, .with-us-component .caption p {
    text-align: left; }
  .with-us-component .caption-col.visible {
    transform: translateY(-77px); }
  .scroll-to-top {
    bottom: 110px; }
  .scroll-to-top svg {
    height: 64px; }
  .form-group .btn-green, .form-group .btn-brown, .form-group .btn-black {
    width: 100%; }
  .contact-form-section {
    padding-bottom: 63px; }
  .contact-form-section .form-group:last-of-type {
    margin-bottom: 0px; }
  .find-us-section h5 {
    font-size: 36px;
    line-height: 18px; }
  .find-us-section p {
    margin-bottom: 16px; }
  .find-us-section .col-md-6:first-of-type {
    padding-bottom: 72px; }
  .find-us-section {
    padding-top: 80px;
    padding-bottom: 104px; }
  ol.pp, ul.pp {
    padding-left: 25px;
    margin-bottom: 31px; }
  .policy-section {
    padding-bottom: 11px; }
  .fw-presentation .caption {
    padding-top: 24px;
    padding-bottom: 20px;
    top: 182px;
    right: 15px; }
  .fw-presentation .main-btn {
    margin-top: 116px !important;
    margin-left: 15px; }
  .fw-presentation {
    padding-bottom: 71px; }
  .fw-presentation .caption h5 {
    line-height: 30px; }
  .projects-section .caption {
    width: 230px;
    /*height:198px;*/
    height: 172px;
    bottom: 32px; }
  .projects-section .project-presentation {
    padding-bottom: 20px; }
  .projects-section {
    padding-bottom: 45px; }
  .project-details .seperator-line {
    display: none; }
  .project-details-component .right {
    border-left: 1px solid #B7BEBF;
    border-right: 1px solid #B7BEBF;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 27px;
    padding-bottom: 9px; }
  .item {
    margin-bottom: 17px; }
  .project-details-component {
    padding-bottom: 55px; }
  .image-with-caption-component.splitted .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333%;
    flex: 0 0 58.333%;
    max-width: 58.333%; }
  .image-with-caption-component.splitted .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.667%;
    flex: 0 0 41.667%;
    max-width: 41.667%; }
  .image-with-caption-component p {
    padding-top: 13px; }
  .image-with-caption-component {
    padding-bottom: 49px; }
  .carousel-mfp svg {
    width: 40px;
    height: 40px; }
  .single-project-nav {
    width: 100px;
    padding-top: 12px;
    padding-bottom: 9px;
    bottom: -30px; }
  .single-project-nav .seperator {
    margin-left: 20px;
    margin-right: 20px;
    height: 25px; }
  .single-project-nav .slick-arrow svg {
    width: 8px;
    height: 14px; }
  .single-project-section .spacer {
    height: 60px; }
  .paginate-projects {
    border-top: 1px solid #717E8034;
    border-bottom: 1px solid #717E8034;
    padding-top: 19px;
    padding-bottom: 18px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    font-size: 13px;
    margin-bottom: 43px; }
  .paginate-projects:after {
    content: '';
    height: 100%;
    width: 1px;
    background: #717E8034;
    position: absolute;
    left: 1px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; }
  .paginate-seperator {
    display: none; }
  .paginate-projects .left, .paginate-projects .right {
    width: 50%; }
  .paginate-projects .left svg {
    margin-right: 9px;
    width: 10px;
    height: 9.75px; }
  .paginate-projects .right svg {
    margin-left: 9px;
    width: 10px;
    height: 9.75px; }
  .inner-projects .single {
    transform: unset;
    opacity: 1; }
  .projects-section.inner-projects {
    padding-bottom: 25px; }
  .no-p-mb {
    padding-left: 0px;
    padding-right: 0px; }
  .image-with-caption-component {
    padding-bottom: 64px; }
  .image-with-caption-component.non-splitted .no-p-mb {
    padding-bottom: 22px; }
  .image-with-caption-component.non-splitted .col-md-7 {
    order: 1; }
  .image-with-caption-component.non-splitted .col-md-5 {
    order: 2; }
  /*  .container, .container-fluid {
      padding-left:20px;
      padding-right:20px;
    }
    .row {
      margin-left:-20px;
      margin-right:-20px;
    }*/
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-left: 20px;
    padding-right: 20px; } }

/* POPUPS */
.modal-dialog.modal-xxl {
  max-width: 100%;
  margin: 0; }

.modal-dialog.modal-xxl .modal-content {
  height: 100%;
  border: unset;
  border-radius: unset; }

#carouselModal {
  padding-right: 0px !important; }

.modal-close {
  font-size: 16px;
  line-height: 1;
  color: #CF814A;
  text-decoration: unset;
  position: absolute;
  top: 5px;
  right: 15px;
  z-index: 1; }

.modal-close:hover {
  text-decoration: unset; }

@media (min-width: 576px) {
  .modal-dialog.modal-xxl {
    max-width: 100%;
    margin: 0; } }

.sticky-side-link {
  position: fixed;
  top: 50%;
  transform: rotateZ(90deg);
  right: -35px;
  color: #3C3C3C;
  border-bottom: 1px solid #3C3C3C;
  font-size: 16px;
  line-height: 1;
  padding-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  text-decoration: unset;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out; }

.sticky-side-link.sticky-link {
  z-index: 1;
  visibility: visible;
  opacity: 1; }

.sticky-side-link:hover {
  text-decoration: unset; }

.estates-burger-bg svg circle {
  fill: #CD7F45; }

@media (max-width: 1199px) {
  .estates-navbar-collapse {
    background-color: #CD7F45; } }

/* ESTATES ONLY */
/*.estates-page .navbar-brand img {
  padding-top:8%;
  padding-bottom:8%;
}*/
.mouse-pointer, .mouse-pointer * {
  cursor: pointer; }

.with-arrow {
  max-width: 187px; }

.with-arrow .arrow-r {
  line-height: 0.2; }

.with-arrow .arrow-r svg path {
  fill: white; }

.page-header.estates-header {
  background: url("/demo/estates-header.jpg"), linear-gradient(to top, #00000073, transparent);
  background-repeat: no-repeat;
  background-size: cover; }

.estates-page .tagline {
  position: relative;
  color: #CD7F45; }

.estates-page .tagline:before {
  content: '';
  background-color: #CD7F45;
  width: 28px;
  height: 2px;
  position: absolute;
  left: -42px;
  top: 50%; }

.project-header .tagline {
  position: relative; }

.project-header .tagline a {
  text-decoration: unset !important; }

.project-header .tagline:before {
  content: '';
  background-color: #01c71f;
  width: 28px;
  height: 2px;
  position: absolute;
  left: -42px;
  top: 50%; }

.estates-page .container.with-tagline:before {
  content: unset; }

.estates-page .container.with-tagline .tagline:before {
  width: 100%;
  left: calc(-100% - 15px); }

.estate-details .project-details .seperator-line {
  top: 0;
  height: 50%; }

.estate-details.project-details-component .left, .estate-details.project-details-component .right {
  opacity: 1;
  transform: unset; }

.estate-details-wrapper {
  background: #F5F5F5;
  /*padding-top:63px;*/
  padding-bottom: 45px; }

.mfp-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; }

.video-component, .estates-carousel-component {
  padding-top: 32px;
  padding-bottom: 47px; }

.design-line-wrapper {
  background: #F5F5F5;
  padding-top: 63px; }

.design-line-wrapper .basic-text-component {
  padding-bottom: 0; }

.footer-logo-estates {
  margin-bottom: 34px; }

.interior-section {
  /*padding-top:43px;*/
  padding-bottom: 43px; }

.interior-content {
  margin-bottom: 0; }

.interior-caption {
  font-size: 16px;
  line-height: 36px;
  /*font-weight:bold;*/
  /*font-family: 'PF Futura Neu';*/
  font-family: 'Roboto', sans-serif; }

.interior-content {
  font-size: 16px;
  line-height: 24px; }

.interior-caption, .interior-content-slick {
  max-width: 100%; }

.interior-row {
  padding-bottom: 34px; }

.amenities-wrapper {
  background-color: #F5F5F5;
  padding-top: 45px;
  padding-bottom: 58px; }

.amenities-wrapper .basic-text-component {
  padding-bottom: 0; }

.amenities-list {
  list-style-position: inside;
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 40px; }

.amenities-list li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #CD7F45;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 22px;
  font-size: 36px;
  margin-left: 15px; }

.amenities-list li {
  border-top: 1px solid #B9BFC0;
  text-transform: uppercase;
  padding-top: 16px;
  padding-bottom: 11px;
  padding-left: 0px; }

.amenities-list li:last-of-type {
  border-bottom: 1px solid #B9BFC0; }

.estates-page .contact-form-section *, .estates-page .footer .socials {
  transform: unset;
  opacity: 1; }

.estates-page .contact-form-section {
  padding-top: 38px;
  padding-bottom: 51px; }

.estates-carousel .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: auto;
  padding-top: 14px; }

.estates-carousel .slick-dots li {
  margin: 0;
  width: calc((100% / 8 - 24px));
  height: 3px;
  margin-left: 6px;
  margin-right: 6px; }

.estates-carousel .slick-dots li button {
  text-indent: -9999px;
  border: unset;
  border-radius: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #717E80;
  outline: unset; }

.estates-carousel .slick-dots li button:focus {
  outline: unset; }

.estates-carousel .slick-dots li.slick-active button, .estates-carousel .slick-dots li:hover button {
  background-color: #CD7F45; }

.estates-carousel-wrapper {
  background-color: #F5F5F5; }

.estates-carousel-component .left, .estates-carousel-component .right {
  cursor: pointer;
  position: absolute;
  top: 27%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px; }

.estates-carousel-component .left svg, .estates-carousel-component .right svg {
  width: 100%;
  height: auto; }

.estates-carousel-component .left {
  left: 15px; }

.estates-carousel-component .right {
  right: 15px; }

.estates-carousel-component .left:hover svg rect, .estates-carousel-component .right:hover svg rect {
  opacity: 1; }

.estates-carousel-component .left:hover svg path, .estates-carousel-component .right:hover svg path {
  fill: #cd7f45; }

.estates-carousel-component p {
  margin-top: 25px; }

.word-carousel-nav {
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px; }

.word-carousel-nav .navlink {
  cursor: pointer;
  width: calc(100% / 3);
  padding-bottom: 12px;
  border-bottom: 1px solid #CD7F45; }

.word-carousel-nav .navlink.boldlink p {
  font-weight: 600; }

.word-carousel-nav .navlink:nth-child(1) {
  margin-right: 15px; }

.word-carousel-nav .navlink:nth-child(2) {
  margin-right: 15px;
  margin-left: 15px; }

.word-carousel-nav .navlink:nth-child(3) {
  margin-left: 15px; }

.word-carousel .slick-slide img {
  height: 276px;
  object-fit: cover; }

.estates-text-component {
  padding-top: 60px;
  padding-bottom: 20px; }

.sustainability + .estates-text-component-wrapper {
  background-color: #f5f5f5; }

.estate-content .contact-form-section-wrapper {
  background-color: #F5F5F5; }

.estate-content .slider-wrapper, .estate-content .slider-wrapper-new {
  margin-bottom: 50px; }

.cta-component {
  background: #CD7F45;
  padding-top: 69px;
  padding-bottom: 79px; }

.plans-section {
  background: #F5F5F5;
  padding-top: 54px;
  padding-bottom: 52px; }

.plans-section .basic-text-component {
  padding-bottom: 0px; }

.estates-page .table tr td {
  padding-top: 16px;
  padding-bottom: 11px; }

.estates-page .table tr td:first-of-type {
  text-transform: uppercase;
  padding-left: 35px; }

.estates-page .table tr td:last-of-type {
  padding-right: 30px;
  text-align: right; }

.estates-page .table td {
  border-color: #B9BFC0; }

.estates-page .table tr:last-of-type {
  border-bottom: 1px solid #B9BFC0; }

.estates-page .nav-tabs {
  border: unset; }

.estates-page .nav-tabs .nav-item.show .nav-link, .estates-page .nav-tabs .nav-link.active, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover, .nav-tabs .nav-link {
  border: unset;
  background: transparent;
  color: #3C3C3C;
  border-radius: 0;
  border-bottom: 1px solid #B6C6C8; }

.estates-page .nav-tabs .nav-item.show .nav-link, .estates-page .nav-tabs .nav-link.active, .estates-page .nav-tabs .nav-item .nav-link:hover {
  font-weight: bold;
  color: #3C3C3C; }

.estates-page .nav-tabs .nav-item {
  margin-left: 15px;
  margin-right: 15px; }

.estates-page .tab-content {
  padding-top: 38px;
  padding-bottom: 42px; }

.form-check-inline {
  align-items: start; }

.form-check-inline .form-check-input {
  margin-top: 0.3rem; }

.form-check-inline label.small {
  line-height: 24px; }

.branch p a {
  text-decoration: unset;
  color: #C3D0D2; }

.contact-branches p a {
  color: #717e80; }

.branch p a:hover {
  color: #FFFFFF;
  text-decoration: unset; }

.contact-branches p a:hover {
  color: #717e80; }

.contact-branches a:hover {
  text-decoration: unset; }

.cat-nav {
  padding-top: 30px;
  padding-bottom: 50px; }

.cat-nav a, .cat-nav .a {
  font: normal normal bold 18px/22px PF Futura Neu;
  letter-spacing: 0px;
  color: #3C3C3C;
  text-decoration: unset;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase; }

.cat-nav .dropdown-toggle {
  width: 100%;
  display: block;
  border-radius: 0;
  border: 1px solid #DEDEDE;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 21px;
  padding-right: 21px; }

.cat-nav .dropdown-menu {
  border-radius: 0;
  width: 100%;
  min-width: unset;
  padding-top: 0px;
  padding-bottom: 0px; }

.cat-nav .dropdown-menu a {
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 21px;
  padding-right: 21px; }

.estates-page .parallax-bg {
  height: 136px; }

.fade-images {
  max-width: 100%; }

.price-breakdown {
  padding-bottom: 60px; }

.price-breakdown .very-small-title {
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 11px; }

.price-breakdown .left {
  font-weight: bold; }

.price-breakdown .item {
  border-top: 1px solid #b6c6c8;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px; }

.price-breakdown .item:last-of-type {
  border-bottom: 1px solid #b6c6c8; }

.price-breakdown .table-wrapper {
  padding-left: 15px;
  padding-right: 15px; }

.sustainability {
  padding-top: 50px;
  padding-bottom: 70px; }

/*.slider-wrapper-new .slider .slick-slide img {
  transform:matrix(1, 0, 0, 1, 0, 0);
  transition:opacity 500ms ease-in-out 0s, transform 10s linear 0s;
}
@keyframes movingImage {
  from {
    transform:matrix(1, 0, 0, 1, 0, 0);
  }
  to {
    transform:matrix(1, 0, 0, 1, -100, 0);
  }
}
.slider-wrapper-new .slider .slick-current img {
  transform:matrix(1, 0, 0, 1, -100, 0);
}*/
@keyframes movingImage {
  from {
    transform: matrix(1, 0, 0, 1, 0, 0); }
  to {
    transform: matrix(1, 0, 0, 1, -200, 0); } }

.slider-wrapper-new .slider .slick-slide img {
  width: 150% !important;
  max-width: unset !important; }

.slider-wrapper-new .slider .slick-moving img {
  animation-name: movingImage;
  animation-duration: 14s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  /*animation-fill-mode: forwards;*/
  animation-timing-function: linear; }

.slider-wrapper-new .logo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 67px;
  margin: auto;
  max-width: 300px; }

.list-component {
  padding-top: 45px;
  padding-bottom: 50px; }

.list-component .heading {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 16px;
  font-family: 'PF Futura Neu'; }

/*.list-component ul {
  padding-left: 0px;
  list-style-position: inside;
  margin-top:0px;
  margin-bottom:17px;
}
.list-component li {
  font-size:14px;
  line-height:24px;
  margin-bottom:0px;
}
.list-component li:marker {
  margin-right:5px;
}*/
.list-component ul {
  list-style-position: inside;
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 17px; }

.list-component li {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0px;
  display: flex; }

.list-component li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #CD7F45;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 22px;
  font-size: 30px;
  margin-left: 0px; }

.word-carousel-heading {
  font-size: 18px; }

@media (min-width: 768px) {
  .modal-dialog.modal-xxl .modal-content {
    height: 100vh; }
  .modal-close {
    top: 43px;
    right: 48px; }
  .list-component {
    padding-top: 50px;
    padding-bottom: 50px; }
  .list-component ul {
    margin-bottom: 0px; }
  .word-carousel .slick-slide img {
    height: 373px; }
  .word-carousel-words {
    padding-top: 35px; }
  .estate-content .slider-wrapper, .estate-content .slider-wrapper {
    margin-bottom: 90px; }
  .cat-nav a {
    font: normal normal bold 11px/11px PF Futura Neu; }
  .cat-nav a:hover {
    color: #00C71F; }
  .cat-nav a.current {
    color: #C9C9C9; }
  .cat-nav .seperator-cat-nav {
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    color: #C9C9C9;
    font-weight: 300; }
  .estate-details-wrapper {
    /*padding-top:91px;*/
    padding-bottom: 83px; }
  .video-component, .estates-carousel-component {
    padding-top: 58px;
    padding-bottom: 93px; }
  .estates-carousel-component p {
    margin-top: 25px; }
  .estates-carousel-component .left, .estates-carousel-component .right {
    top: 41%; }
  .word-carousel-nav {
    max-width: 48%; }
  .estates-text-component {
    padding-top: 100px;
    padding-bottom: 80px; }
  .footer-logo-estates {
    margin-bottom: 59px; }
  .design-line-wrapper {
    padding-top: 76px;
    margin-bottom: 144px; }
  .design-line-wrapper .images {
    transform: translateY(144px); }
  .interior-section {
    /*padding-top:93px;*/
    padding-bottom: 93px; }
  .interior-caption {
    font-size: 16px;
    line-height: 36px; }
  .interior-content {
    font-size: 14px;
    line-height: 22px; }
  .amenities-list {
    padding-top: 10px; }
  .amenities-list li {
    padding-top: 14px;
    padding-bottom: 15px;
    margin-bottom: 0;
    font-size: 18px; }
  .amenities-wrapper {
    padding-top: 70px;
    padding-bottom: 76px; }
  .estates-page .contact-form-section {
    padding-top: 48px;
    padding-bottom: 100px; }
  .cta-component {
    padding-top: 63px;
    padding-bottom: 63px; }
  .estates-page .table tr td {
    padding-top: 16px;
    padding-bottom: 17px; }
  .plans-section {
    padding-top: 79px;
    padding-bottom: 89px; }
  .estates-page .tab-content {
    padding-top: 47px;
    padding-bottom: 47px; }
  .estates-page .parallax-bg {
    height: 324px; }
  .word-carousel-nav {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 35px;
    padding-top: 0px;
    padding-left: 0px;
    margin-top: 0px; }
  .amenities-list li::before {
    margin-left: 35px; }
  .sustainability {
    padding-top: 75px;
    padding-bottom: 100px; }
  .price-breakdown {
    padding-bottom: 100px; } }

@media (min-width: 992px) {
  .list-component {
    /*    padding-top:75px;
    padding-bottom:150px;*/
    padding-top: 80px;
    padding-bottom: 80px; }
  .list-component li {
    font-size: 22px;
    line-height: 51px; }
  .list-component .heading {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 23px; }
  .word-carousel .slick-slide img {
    height: 547px; }
  .word-carousel-words {
    padding-top: 75px; }
  .word-carousel-nav {
    bottom: 75px; }
  .with-arrow {
    max-width: 229px; }
  .estate-content .slider-wrapper, .estate-content .slider-wrapper {
    margin-bottom: 150px; }
  .sustainability {
    padding-top: 100px;
    padding-bottom: 70px; }
  .price-breakdown {
    padding-bottom: 70px; }
  .cat-nav a {
    font: normal normal bold 14px/22px PF Futura Neu; }
  .cat-nav .seperator-cat-nav {
    font-size: 24px; }
  .estates-page .parallax-bg {
    height: 809px; }
  .estates-carousel-component p {
    margin-top: 25px; }
  .estates-carousel-component .left, .estates-carousel-component .right {
    top: 43%;
    width: 67px;
    height: 67px; }
  .estates-text-component {
    padding-top: 100px;
    padding-bottom: 0px; }
  .price-breakdown .very-small-title {
    margin-bottom: 36px; }
  .price-breakdown .item {
    padding-left: 15px;
    padding-right: 10px; }
  .amenities-list {
    margin-bottom: 100px; }
  .word-carousel-heading {
    font-size: 50px;
    margin-bottom: 10px; } }

@media (min-width: 1200px) {
  .page-header {
    /*margin-bottom:225px;*/
    height: 560px; }
  .page-header h4 {
    /*margin-bottom:156px;*/ }
  .estate-details-wrapper {
    /*padding-top:164px;*/
    padding-bottom: 80px; }
  .estate-details .project-details {
    padding-top: 81px; }
  .estate-details .project-details img {
    padding-top: 81px; }
  /*  .project-details-component {
      padding-bottom:0px;
    }*/
  .video-component, .estates-carousel-component {
    padding-top: 133px;
    padding-bottom: 169px; }
  .design-line-wrapper {
    margin-bottom: 144px;
    padding-top: 121px; }
  .design-line-wrapper .images {
    transform: translateY(144px); }
  .footer-logo-estates {
    margin-bottom: 46px; }
  .interior-section {
    /*padding-top:129px;*/
    padding-bottom: 80px; }
  .interior-caption {
    font-size: 16px;
    line-height: 36px; }
  .interior-content {
    font-size: 16px;
    line-height: 30px; }
  .interior-row {
    padding-bottom: 72px; }
  .interior-row.first {
    padding-top: 81px; }
  .amenities-list li {
    padding-top: 14px;
    padding-bottom: 15px; }
  .amenities-wrapper {
    padding-top: 121px;
    padding-bottom: 169px; }
  .estates-page .contact-form-section {
    padding-top: 121px;
    padding-bottom: 92px; }
  .cta-component {
    padding-top: 120px;
    padding-bottom: 140px; }
  .cta-component h4 {
    font-size: 60px;
    line-height: 72px; }
  .estates-page .table tr td {
    padding-top: 16px;
    padding-bottom: 17px; }
  .plans-section {
    padding-top: 121px;
    padding-bottom: 138px; }
  .estates-page .tab-content {
    padding-top: 34px;
    padding-bottom: 72px; }
  .about-section .tagline, .estates-section .tagline {
    margin-bottom: 15px; }
  .type3 .project-img {
    max-width: 90%; }
  .interior-row:nth-child(1) .col-md-5 {
    padding-top: 115px; }
  .interior-row:nth-child(2) .col-md-7, .interior-row:nth-child(3) .col-md-7, .interior-row:nth-child(4) .col-md-7 {
    padding-top: 80px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .activities-section .circle-menu {
    left: -1350px;
    top: -220px; }
  .activities-section svg foreignObject {
    transform: translate(35px, 15px); }
  .activities-section svg foreignObject div {
    font: normal normal bold 22px/26px PF Futura Neu; }
  .activities-section .indicators {
    top: 37%; }
  .activities-section .indicators .slick-dots li button {
    width: 31px; }
  .activities-section .indicators .slick-dots .slick-active button, .activities-section .indicators .slick-dots li button:focus, .activities-section .indicators .slick-dots li:hover button {
    width: 125px; }
  .estates-section-wrapper .bg-img {
    max-width: 352px;
    top: 100px;
    /*top:0;*/ } }

@media (min-width: 992px) and (max-width: 1199px) {
  .estates-section-wrapper .bg-img {
    max-width: 352px;
    /*top: 100px;*/
    top: 0; }
  .activities-section .circle-menu {
    top: -420px;
    left: -1325px; }
  /*  .activities-section .slick-slide .offset-lg-2 {
      margin-left:25%;
    }
    .activities-section .slick-slide .offset-lg-1 {
      margin-left:0px;
    }*/
  /*  .activities-section .indicators {
      display:none !important;
    }*/ }

@media (min-width: 768px) {
  .soon-section img {
    min-width: 512px; } }

@media (min-width: 1200px) and (max-width: 1279px) {
  .header-navbar .navbar-nav .nav-link.estates-link, .header-navbar .navbar-nav .nav-link.main-link {
    padding-left: 8px;
    padding-right: 8px; } }

@media (min-width: 1200px) and (max-width: 1365px) {
  .activities-section .circle-menu {
    left: -1100px;
    top: -320px; } }

@media (min-height: 550px) and (max-height: 900px) and (min-width: 768px) and (max-width: 1200px) {
  .intro-section {
    padding-top: 20%; }
  .estates-page .intro-section.new {
    padding-top: 0px; } }

@media (min-width: 1200px) and (max-width: 1599px) {
  .home-projects-section.visible-lines {
    background-position-x: -70vw; } }

@media (min-width: 1600px) and (max-width: 1700px) {
  .home-projects-section.visible-lines {
    background-position-x: -60vw; } }

@media (max-width: 767px) {
  .word-carousel-heading {
    text-transform: uppercase;
    margin-bottom: 5px !important; } }
