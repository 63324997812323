@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/magnific-popup/src/css/main.scss";

@font-face {
  font-family: 'PF Futura Neu';
  src: url('/assets/fonts/subset-PFFuturaNeu-Bold.eot');
  src: url('/assets/fonts/subset-PFFuturaNeu-Bold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/subset-PFFuturaNeu-Bold.woff2') format('woff2'),
  url('/assets/fonts/subset-PFFuturaNeu-Bold.woff') format('woff'),
  url('/assets/fonts/subset-PFFuturaNeu-Bold.ttf') format('truetype'),
  url('/assets/fonts/subset-PFFuturaNeu-Bold.svg#PFFuturaNeu-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

#languageModal .modal-content {
  border-radius: 0;
  background-color: #00c71f;
}
#languageModal .modal-header {
  border-bottom-color:#FFFFFF;
}
#languageModal .modal-title {
  font-size: 18px;
  color: #FFFFFF;
}
#languageModal .modal-body a, #languageModal .modal-body {
  color: #FFFFFF;
  font-family: 'PF Futura Neu';
}
#languageModal .close {
  color: #FFFFFF;
  text-shadow: unset;
  opacity: 1;
}
iframe#map {
  pointer-events:none;
  width:100%;
}
iframe#map.clickable {
  pointer-events:auto;
}

.nonsticky-logo {
  display:block;
}
.sticky-logo {
  display:none;
}


#start-content {
  height:1px;
  opacity:0;
  visibility:hidden;
}
#start-content.scroll-earlier {
  position:absolute;
  bottom:300px;
}
#navigation, #navigation .navbar-brand img {
  transition:all 0.3s ease-in-out;
}
#navigation {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
#navigation.sticky-navigation {
  position: fixed;
  background:transparent;
}
.basic-text-component-effect {
  overflow:hidden;
}
body.inner-page {
  padding-top:84px;
}

/* TYPOGRAPHY AND GENERAL BODY ELEMENTS */
body {
  font-family: 'Roboto', sans-serif;
}
h1, h2, h3, h4, h5, h6, .small-title, .very-small-title, .item .headline, .projects-section .content a,   .projects-section .caption .subtitle, .projects-section .caption .title, .projects-section .caption .readmore, .tagline, .btn-text, label, .play, .btn-green, .btn-brown, .btn-black, .inline-words .word, .paginate-projects .left div, .paginate-projects .right div {
  font-family: 'PF Futura Neu';
}
body {
  color:#3C3C3C;
}
body, p {
  font-size:16px;
  line-height:24px;
}
p {
  margin-bottom:30px;
}
h2 {
  font-size:30px;
  line-height:32px;
  margin-bottom:24px;
}
h3 {
  font-size:36px;
  line-height:36px;
  margin-bottom:15px;
}
h4 {
  font-size:30px;
  line-height:36px;
  margin-bottom:5px;
}
h5 {
  font-size:24px;
  line-height:18px;
}
h6 {
  font-size:30px;
  line-height:36px;
  margin-bottom:16px;
}
.small-title {
  font-size:18px;
  line-height:24px;
  margin-bottom:10px;
}
.very-small-title {
  font-size:14px;
  line-height:26px;
  margin-bottom:12px;
}
h3.with-sub-fix {
  padding-top:19px;
}
.btn-green, .btn-black {
  font-size:18px;
  line-height:24px;
}
.btn-brown {
  font-size:14px;
  line-height:19px;
}
.header-navbar .nav-link {
  font-size:16px;
  font-weight:bold;
}
.tagline {
  font-size:13px;
}
.main-btn .btn-text {
  font-size:14px;
}
label {
  font-size:14px;
  line-height:19px;
}
label.small {
  font-size:14px;
  line-height:36px;
}
.footer p {
  font-size:18px;
  line-height:29px;
  margin-bottom:34px;
}
.footer .mail a, .footer .phone a {
  font-size:18px;
  line-height:29px;
  color:#C3D0D2;
  margin-left:10px;
}
.footer .mail a:hover, .footer .phone a:hover {
  color:#FFFFFF;
}
.footer a, .footer a svg path {
  transition:all 0.6s ease-in-out;
}
.footer a:hover, .footer a:focus, .footer a:active {
  text-decoration:unset;
}
.footer .copy {
  font-size:12px;
  line-height:24px;
}
.footer .pp-link {
  font-size:12px;
  line-height:24px;
}
.footer .pp-link-with-space {
  margin-right:15px;
}
.footer-navbar a {
  font-size:14px;
  line-height:24px;
}
.page-header h4 {
  margin-bottom:94px;
}
.inline-words .word {
  font-size:30px;
}
.projects-section .caption .subtitle {
  color:#717E80;
  font-size:13px;
  line-height:22px;
  margin-bottom:5px;
}
.projects-section .caption .title {
  /*font-size:24px;*/
  font-size:20px;
  line-height:24px;
  /*margin-bottom:23px;*/
  margin-bottom:10px;
  word-break: break-word;
}
.projects-section .caption .readmore {
  font-size:14px;
  /*line-height:26px;*/
  line-height:20px;
  /*margin-bottom:14px;*/
  margin-bottom:10px;
}
.projects-section .caption .readmore .arrow-r {
  line-height: 0.1;
  margin-left: 10px;
}
.readmore .arrow-r svg {
  width:8.17px;
  height:7.69px;
}
/*ul, ol {
  padding-left:30px;
}*/
ul li, ol li {
  margin-bottom:10px;
}
ul li.nav-item {
  margin-bottom:0px;
}
ul.pp, ol.pp {
  margin-bottom:52px;
  padding-left:45px;
}
ul.pp {
  list-style: none; /* Remove default bullets */
}
ul.pp li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #00C71F; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
hr.pp {
  margin-top: 41px;
  margin-bottom: 21px;
}
.text-body {
  color:#3C3C3C !important;
}
.text-lightgray {
  color:#717e80;
}
.text-green {
  color:#00C71F;
}
.small-title {
  font-weight:bold;
}
.very-small-title {
  font-weight:bold;
}
.projects-section .caption .subtitle {
  color:#717E80;
}
.container.with-tagline {
  position:relative;
}
.container.with-tagline:before {
  content: '';
  position: absolute;
  left: calc(-100% + 42px);
  top: 10px;
  height: 2px;
  width: 100%;
  background-color: #00c71f;
}
.container.with-tagline.reverse:before {
  background-color:#FFFFFF;
}
.tagline {
  margin-left:42px;
}
.with-tagline:before {
  opacity:0;
  transition:all 0.6s ease-out;
}
.intro-section .with-tagline:before {
  opacity:1;
}
.intro-estates a {
  color:#CD7F45;
}
.centered-text-component-wrapper {
  background-color:#CD7F45;
  padding-top:77px;
  padding-bottom:77px;
}
.redirect-wrapper {
  /*background-color:#cd7f45cf;*/
  padding-top:50px;
  padding-bottom:50px;
}
.intro-estates .estates-text-component {
  padding-top:0px !important;
}
.visible .with-tagline:before {
  opacity:1;
}
.visible.with-tagline:before {
  opacity:1;
}
/* NAVIGATION */
.header-navbar {
  background-color:#FFFFFF;
}
.header-navbar.is-transparent {
  position:absolute;
  left:0;
  right:0;
  top:0;
  z-index:2;
  background-color:transparent;
}
.header-navbar .nav-link {
  color:#3C3C3C;
  border:1px solid transparent;
  position:relative;
  transition:all 0.3s ease-in-out;
}
.header-navbar.is-transparent .nav-link {
  color:#FFFFFF;
}
.header-navbar.is-transparent .seperator-item .nav-link {
  background-color:#FFFFFF;
  opacity:0.43;
  height:22px;
  width:1px;
  padding-top:0px;
  padding-bottom:0px;
  padding-left:0px;
  padding-right:0px;
  margin-left:20px;
  margin-right:20px;
}
.header-navbar.sticky-navigation .seperator-item .nav-link {
  background-color:#717E80;
  border:unset;
}
.header-navbar .seperator-item .nav-link {
  background-color:#717E80;
  opacity:0.42;
  height:22px;
  width:1px;
  padding-top:0px;
  padding-bottom:0px;
  padding-left:0px;
  padding-right:0px;
  margin-left:20px;
  margin-right:20px;
  border:unset;
}
.header-navbar .nav-link.social {
  line-height:30px;
}
.header-navbar .nav-link.main-link:before {
  content:'';
  position:absolute;
  margin:auto;
  left:0;
  right:0;
  top:-9px;
  background-color:#00C71F;
  width:8px;
  height:8px;
  border-radius:50%;
  opacity:0;
  transition:all 0.3s ease-in-out;
}
.estates-page .header-navbar .nav-link.main-link:before {
  background-color:#cd7f45;
}
.header-navbar .nav-link:hover.main-link:before, .header-navbar .nav-link.current.main-link:before {
  opacity:1;
}
.header-navbar .nav-link.estates-link {
  border:1px solid #00C71F;
  font-weight:900;
}
.header-navbar .nav-link.estates-link:hover {
  background-color:#00C71F;
  color:#3C3C3C;
}
.header-navbar .nav-link svg {
  height:17px;
}
.header-navbar .nav-link svg path {
  fill:#3c3c3c;
}
.header-navbar.is-transparent .nav-link svg path {
  fill:#FFFFFF;
}
.navbar-brand {
  padding:0px;
  margin:auto;
}
.header-navbar .dropdown-menu {
  border-radius:0;
  top:80%;
}
.header-navbar .dropdown-menu.dropdown-with-lines {
  min-width:227px;
  padding-top:25px;
  padding-bottom:20px;
}
.header-navbar .dropdown-menu.dropdown-lang {
  width:67px;
  min-width:67px;
  max-width:67px;
  padding-top:8px;
  padding-bottom:4px;
}
.header-navbar .dropdown-menu.dropdown-lang .dropdown-item {
  padding-left:13px;
}
.header-navbar .dropdown-with-lines .dropdown-item {
  padding-left:27px;
  position:relative;
  transition: all .3s ease-in-out;
  color:#717e80;
}
.header-navbar .dropdown-with-lines .dropdown-item:hover {
  color:#3c3c3c;
}
.header-navbar .dropdown-with-lines .dropdown-item:before {
  content: '';
  display: inline-block;
  background-color: #00c71f;
  transition: all .3s ease-in-out;
  height: 2px;
  width: 0;
  position: absolute;
  left: 0;
  top: 45%;
}
.header-navbar .dropdown-menu .dropdown-item:hover, .header-navbar .dropdown-menu .dropdown-item:active {
  background-color:transparent;
  color:#3C3C3C;
}
.header-navbar .dropdown-with-lines .dropdown-item:hover, .header-navbar .dropdown-with-lines .dropdown-item:active {
  padding-left:62px;
}
.header-navbar .dropdown-with-lines .dropdown-item:hover:before {
  width:35px;
}
.header-navbar .dropdown-toggle::after {
  content:unset;
}
.menu-arrow {
  margin-left:8px;
}

/* FOOTER */
.scroll-to-top {
  cursor:pointer;
  position:absolute;
  bottom:15px;
  right:15px;
  z-index:9999;
}
.scroll-to-top path, .scroll-to-top line {
  transition:all 0.6s ease-in-out;
}
.scroll-to-top:hover path, .scroll-to-top:hover line {
  stroke:#FFFFFF;
}
.footer {
  position:relative;
  padding-top:115px;
  padding-bottom:50px;
  overflow:hidden;
  background:url('/demo/footer-lines.svg') #3C3C3C;
  background-repeat:no-repeat;
  background-position-x:-200%;
  background-position-y:100%;
  transition:all 3s ease-in-out;
}
.estates-page .footer {
  background: #3c3c3c;
}
.footer.visible-lines {
  background-position-x:0%;
}
.footer .divider {
  height:2px;
  width:77px;
  background-color:#00C71F;
  margin:auto;
}
.footer h5 {
  margin-top:10px;
  margin-bottom:29px;
}
.footer p {
  color:#FFFFFF;
}
.estates-page .footer p {
  color:#B6C6C8;
}
.footer .socials {
  padding-top:135px;
  padding-bottom:45px;
}
.footer .links {
  padding-bottom:150px;
}
.footer .copy {
  color:#717E80;
}
.footer .pp-link {
  color:#717E80;
}
.footer .pp-link:hover {
  color:#FFFFFF;
}
.footer .left, .footer .right, .footer .socials, .footer .links {
  opacity:0;
  transition: all 0.6s ease-out;
}
.footer .left {
  transform:translateX(-100px);
}
.footer .right {
  transform:translateX(100px);
}
.footer .socials, .footer .links {
  transform:translateY(100px);
}
.footer .left.visible, .footer .right.visible, .footer .socials.visible, .footer .links.visible {
  opacity:1;
  transform:unset;
}
.nav-circle .nav-link {
  width:4px;
  height:4px;
  border-radius:50%;
  padding:0 !important;
  background-color:#00C71F;
}
.nav-circle {
  display:flex;
  align-items:center;
  justify-content:center;
}
.footer-navbar {
  padding-left:0px;
  padding-right:0px;
}
.footer-navbar a:hover {
  color:#FFFFFF;
}
.footer .socials a:hover path {
  fill:#FFFFFF;
}
.footer .socials a {
  padding-left:20px;
  padding-right:20px;
}
.footer .socials a:first-of-type {
  padding-left:0px;
}
.footer .socials a:last-of-type {
  padding-right:0px;
}

/* GLOBALS */
.fix-md-order {
  order:2;
}
a {
  color:#717E80;
}
a:hover {
  color:#717E80;
}
a.play:hover, a.main-btn:hover {
  text-decoration:none;
}
.fh {
  height:100vh;
  width:100%;
}
.img-cover {
  object-fit:cover;
  width:100%;
  height:100%;
}
.pr-normal {
  padding-right:30px;
}
.pl-normal {
  padding-left:30px;
}
.line {
  position:absolute;
  left:0;
  height:2px;
  margin-top:10px;
  width:52%;
}
.super {
  position: relative;
  padding-left: 20px;
  width: 48%;
  margin-left: 51%;
}
.outer-ring {
  width: 74px;
  height: 74px;
  border-radius: 50%;
  transition:all 0.3s ease-in-out;
}
.middle-ring {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #ffffff54;
  transform:scale(0.89);
  transition:all 0.3s ease-in-out;
}
.green .middle-ring {
  border: 1px solid #00C71F54;
}
.inner-ring {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /*transform:scale(0.74);*/
  transform:scale(0.7022);
  background-color: #FFFFFF;
  transition:all 0.3s ease-in-out;
}
.estates-page .inner-ring {
  background-color:#CD7F45;
}
.estates-page .middle-ring {
  border:1px solid #CD7F45;
}
.green .inner-ring {
  background-color:#00C71F;
}
.main-btn {
  position:relative;
  display:inline-block;
  width:263px;
}
.main-btn:hover .middle-ring {
  transform:scale(1);
}
.main-btn:hover .inner-ring {
  transform: scale(0.658);
}
.main-btn .btn-text {
  position: absolute;
  left: 32px;
  top: 20px;
}
.btn-green:hover, .btn-brown:hover, .btn-black:hover {
  color:#FFFFFF;
}
.page-header {
  background-blend-mode:multiply;
  height:391px;
}
.page-header .circle {
  position:absolute;
  left:47px; /* 32px + 15px gutter */
  bottom:0;
  transform:translateY(50%);
}
/*.page-header.about-us-header {
  background:url('/demo/about.jpg'), linear-gradient(to top, #00000073, transparent);
}
.page-header.activities-header {
  background:url('/demo/activities_header.jpg'), linear-gradient(to top, #00000073, transparent);
}
.page-header.projects-header {
  background:url('/demo/projects.jpg'), linear-gradient(to top, #00000073, transparent);
}
.page-header.project-header {
  background:url('/demo/project.jpg'), linear-gradient(to top, #00000073, transparent);
}
.page-header.contact-header {
  background:url('/demo/contact.jpg'), linear-gradient(to top, #00000073, transparent);
}
.page-header.policy-header {
  background:url('/demo/policy.jpg'), linear-gradient(to top, #00000073, transparent);
}*/
.page-content {
  background:url('/demo/page-content-lines.svg');
  background-repeat:no-repeat;
  background-position-x: -400%;
  background-position-y: -35%;
  transition:all 3s ease-in-out;
}
.page-content.visible-lines {
  background-position-x: -225%;
}

.basic-text-component {
  padding-bottom:121px;
}
.container.basic-text-component.with-tagline:before {
  top: 65px;
  left: -87%;
  height: 4px;
}
.basic-text-component-effect .headline, .basic-text-component-effect .paragraph {
  opacity:0;
  transition:all 0.6s ease-in-out;
}
.basic-text-component-effect .headline {
  transform:translateX(-100px);
}
.basic-text-component-effect .paragraph {
  transform:translateX(100px);
}
.basic-text-component-effect .headline.visible, .basic-text-component-effect .paragraph.visible {
  opacity:1;
  transform:unset;
}

.inline-words .circle {
  width:10px;
  height:10px;
  background-color:#00C71F;
  border-radius:50%;
  margin-left:35px;
  margin-right:35px;
}
.inline-words .word {
  font-weight:bold;
}
.text-image-component {
  padding-bottom:112px;
  overflow:hidden;
}
.text-image-component .text-image-left, .text-image-component .text-image-right {
  opacity:0;
  transition:all 0.6s ease-in-out;
}
.text-image-component .text-image-left {
  transform:translateX(-100px);
}
.text-image-component .text-image-right {
  transform:translateX(100px);
}
.text-image-component .text-image-left.visible, .text-image-component .text-image-right.visible {
  opacity:1;
  transform:unset;
}
.with-us-component {
  background-color:#F5F5F5;
  margin-bottom: -20px;
}
.with-us-component .container {
  max-width:100%;
}
.with-us-component .bg {
  background-color:#FFFFFF;
  height:133px;
}
.with-us-component .caption {
  padding-left:29px;
  padding-right:34px;
  background-color:#FFFFFF;
  position:relative;
}
.with-us-component .img-col, .with-us-component .caption-col {
  opacity:0;
  transition:all 0.6s ease-in-out;
}
.with-us-component .img-col {
  transform:translateY(100px);
}
.with-us-component .caption-col {
  transform:translateY(100px);
}
.with-us-component .img-col.visible, .with-us-component .caption-col.visible {
  opacity:1;
}
.with-us-component .caption-col.visible {
  transform:translateY(-45px);
}
.with-us-component .img-col.visible {
  transform:translateY(-122px);
}
.with-us-component h5 {
  margin-bottom:21px;
}
.with-us-component .caption .btn-green {
  transform:translateY(50%);
}
.btn-green {
  background-color:#00C71F;
  border-radius:0px;
  padding-left:25px;
  padding-right:25px;
  padding-top:16px;
  padding-bottom:15px;
  transition:all 0.3s ease-in-out;
}
.btn-brown {
  background-color:#CD7F45;
  border-radius:0px;
  padding-left:25px;
  padding-right:25px;
  padding-top:16px;
  padding-bottom:15px;
}
.btn-black {
  background-color:#3C3C3C;
  border-radius:0px;
  padding-left:25px;
  padding-right:25px;
  padding-top:16px;
  padding-bottom:15px;
  color:#FFFFFF;
}
.btn-black.pdf-link {
  background-color:#cd7f45cf;
}
.btn-gray {
  background-color:#F2F5F5;
}
.btn-green:hover, .btn-gray:hover {
  text-decoration:unset;
}
.btn-gray:hover {
  color:black;
}
.error-image svg {
  max-width: 100%;
  height: auto;
}
.error-content {
  padding-bottom:58px;
}
.error-content a {
  margin-bottom:15px;
}
.fw-presentation {
  padding-bottom:101px;
  opacity:0;
  transform:translateY(100px);
  transition:all 0.6s ease-in-out;
}
.fw-presentation.visible {
  opacity:1;
  transform:unset;
}
.fw-presentation .caption {
  position:absolute;
  left:15px;
  top:75px;
  background-color:#FFFFFF;
  padding-top:38px;
  padding-bottom:52px;
  padding-left:15px;
  padding-right:37px;
  z-index:1;
}
.fw-presentation .caption h5 {
  color: #3C3C3C;
}
.project-details-component {
  padding-bottom:90px;
}
.project-details-component .left, .project-details-component .right {
  opacity:0;
  transition:all 0.6s ease-in-out;
}
.project-details-component .left {
  transform:translateX(-100px);
}
.project-details-component .right {
  transform:translateX(100px);
}
.project-details-component .left.visible, .project-details-component .right.visible {
  opacity:1;
  transform:unset;
}
.project-details-component .card-columns {
  column-count:2;
  orphans:2;
  widows: 2;
}
.project-details-component .card {
  border:unset;
}
.item {
  margin-bottom:33px;
  padding-left:26px;
}
.item .headline {
  font-size:18px;
  line-height:24px;
  font-weight:bold;
  margin-bottom:3px;
}
.item .paragraph {
  font-size:18px;
  line-height:36px;
}
.project-details .seperator-line {
  content:'';
  background-color:#B7BEBF;
  width:1px;
  height:140%;
  position:absolute;
  top:-90px;
}
.project-details .line1 {
  left:0;
}
.project-details .line2 {
  left:calc(50% - 7.5px)
}
.project-details .line3 {
  right:0;
}
.image-with-caption-component {
  padding-bottom:90px;
  opacity:0;
  transform:translateY(100px);
  transition:all 0.6s ease-in-out;
}
.image-with-caption-component.visible {
  opacity:1;
  transform:unset;
}
.single-project-wrapper {
  position:relative;
}
.single-project-nav {
  background-color:#FFFFFF;
  position:absolute;
  bottom:-57px;
  left:15px;
  right:15px;
  width:164px;
  padding-top:20px;
  padding-bottom:35px;
}
.single-project-nav .seperator {
  width:1px;
  height:42px;
  background-color:#717E8034;
  margin-left:35px;
  margin-right:35px;
}
.single-project-nav .slick-arrow {
  cursor:pointer;
}
.single-project-section {
  opacity:0;
  transform:translateY(200px);
  transition:all 0.6s ease-in-out;
}
.single-project-section.visible {
  opacity:1;
  transform:unset;
}
.single-project-section .spacer {
  height:100px;
}
.single-project-nav .slick-arrow svg path {
  transition:all 0.3s ease-in-out;
}
.single-project-nav .slick-arrow:hover svg path {
  fill:#00C71F;
}
.single-project-wrapper .fs {
  position:absolute;
  top:0;
  right:15px;
  z-index:1;
  cursor:pointer;
}
.single-project-wrapper .fs svg rect {
  transition:all 0.3s ease-in-out;
}
.single-project-wrapper .fs:hover svg rect {
  opacity:1;
}
label {
  font-weight:bold;
}
label.small {
  color:#717E80;
  font-weight:normal;
}
label a {
  color:#3C3C3C;
}
label[for="terms"] {
  font-family: 'Roboto', sans-serif;
}
label a {
  color:#01c71f;
  transition:all .1s ease-in-out;
  text-decoration:unset !important;
}
label a:hover {
  border-bottom: 1px solid #B6C6C8;
  color:#01c71f;
}
.estates-page label a {
  color:#3C3C3C;
}
.estates-page label a:hover {
  border-bottom: 1px solid #B6C6C8;
  color:#3C3C3C;
}
.form-control {
  border-radius:0;
  border-color:#B6C6C8;
  box-shadow:unset !important;
  height:55px;
}
.form-control:focus, .form-control:active {
  border-color:#3C3C3C;
}
.form-control.parsley-error {
  border-color:#C40101;
}
label.with-error {
  color:#C40101;
}
.parsley-errors-list {
  padding-left: 0px;
  margin-top:4px;
  display: block;
  list-style-type: none;
  font-size: 14px;
  line-height: 19px;
  font-style: italic;
  color:#C40101;
}
.form-group {
  margin-bottom:21px;
}
.form-group.last {
  margin-bottom:11px;
}

/* SECTIONS */
.slider {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:-1;
}
.slider-wrapper, .slider-wrapper-new {
  position:relative;
}
.slider-wrapper .slider *, .slider-wrapper-new .slider * {
  height:100%;
  width:100%;
}
.slider-wrapper .slick-slide {
  transition:opacity 1s ease-in-out !important;
}
.slider img {
  height:100%;
  object-fit:cover;
}
.slider-wrapper .indicators {
  cursor:pointer;
  position:absolute;
  z-index:1;
  right:0;
  top:calc(50% - 22px);
}
.slider-wrapper .indicators .slick-dots {
  padding: 0px;
  list-style-type: none;
  margin: 0;
}
.slider-wrapper .indicators .slick-dots li {
  margin-bottom:0;
  text-align:right;
  line-height:0;
  font-size:0;
  padding-top:24px;
  padding-bottom:24px;
}
.slider-wrapper .indicators .slick-dots li button {
  height:2px;
  width:82px;
  background-color:#16E700;
  text-indent:-9999px;
  border:unset;
  transition:all 0.3s ease-in-out;
}
.slider-wrapper .indicators .slick-dots .slick-active button, .slider-wrapper .indicators .slick-dots li:hover button, .slider-wrapper .indicators .slick-dots li button:focus {
  width:182px;
  outline:unset;
}
.slider-wrapper .slick-slide .with-tagline:before {
  opacity:0;
  transition:all 0.6s ease-in-out;
}
.slider-wrapper .slick-current .with-tagline:before {
  opacity:1;
}
.intro-section {
  background:#00000080;
  /*padding-bottom:150px;*/
  padding-top:365px;
}
.intro-section.for-estate {
  padding-top:290px;
}
.estates-page .intro-section.new {
  padding-top:0px;
  height:100%;
}
.intro-section h4 {
  margin-bottom:22px;
}
.intro-section .line {
  background-color:#00C71F;
}
.intro-section .play-circle {
  stroke: #00C71F;
}
.intro-section svg {
  overflow:unset;
}
.intro-section .play .moving-circle {
  animation:pulseCircle 1s infinite;
}
/*.intro-section .play:hover .play-circle {
  animation:pulseCircle 1s infinite;
}*/
.intro-section .play-text {
  margin-left:12px;
}

.about-section {
  background:url('/demo/about-lines.svg') #00C71F;
  background-repeat:no-repeat;
  background-position-x:200%;
  transition:all 2s ease-in-out;
  /*new*/
  padding-top:43px;
  padding-bottom:61px;
  /*end new*/
}
.about-section .line {
  background-color:#FFFFFF;
}
.about-section .tagline, .about-section .headline, .about-section .paragraph, .about-section .main-btn {
  opacity:0;
  transition: all 0.6s ease-out;
}
.about-section .tagline {
  transform:translateX(-100px);
}
.about-section .headline {
  transform:translateX(100px);
}
.about-section .paragraph {
  transform:translateX(-100px);
}
.about-section .main-btn {
  transform:translateY(100px);
}
.about-section .tagline.visible, .about-section .headline.visible, .about-section .paragraph.visible, .about-section .main-btn.visible {
  opacity:1;
  transform:none;
}
.about-section.visible-lines {
  background-position-x:100%;
  background-position-y: 40%;
}

.activities-section {
  background-color:#00C71F;
  /*height:120vh;*/
  /* new */
  /*height:549px;*/
  height:700px;
  z-index: 0;
  /* end new*/
  position:relative;
  /*overflow:hidden;*/
}
.activities-section .circle-menu {
  position: absolute;
  left: -1070px;
  top: -420px;
  bottom: 0;
  z-index:1;
  display:none;
  pointer-events:none;
}
.activities-section .circle-menu svg {
  pointer-events:none;
}
.activities-section .circle-menu svg circle {
  pointer-events:all;
}
.activities-section .circle-menu.circle-menu-desktop {
  display:none;
}
.activities-section .circle-menu.circle-menu-tablet {
  left: -528px; /*768 - 240 */
  top: 109px;
  display:none;
}
.activities-section .circle-menu.circle-menu-mobile {
  /*left:calc(-100% + 68px);
  top:283px;*/
  display:none;
}
.activities-section .circle-menu svg {
  width:1636.3px;
}
.activities-section .circle-menu-tablet svg {
  width:800px;
}
.activities-section .circle-menu-mobile svg {
  width:320px;
}
.activities-section svg #circle1, .activities-section svg #circle2, .activities-section svg #circle3, .activities-section svg #circle4, .activities-section svg #circle5 {
  transition:all 0.3s ease-in-out;
  cursor:pointer;
}
.activities-section svg #circle1:before, .activities-section svg #circle2:before, .activities-section svg #circle3:before, .activities-section svg #circle4:before, .activities-section svg #circle5:before {
  content:'1';
  color:black;
  position:absolute;
  left:0;
  top:0;
}
.activities-section svg foreignObject div {
  font: normal normal bold 50px/66px PF Futura Neu;
  letter-spacing: 0px;
  color: #3C3C3C;
}
.activities-section .circle-menu-tablet svg foreignObject div {
  font: normal normal bold 24px/30px PF Futura Neu;
  letter-spacing: 0px;
  color: #3C3C3C;
}
.activities-section svg foreignObject {
  opacity:0;
  transition:all 0.3s ease-in-out;
}
.activities-section svg circle.current + foreignObject {
  opacity:1;
}
.activities-section .indicators {
  cursor:pointer;
  position:absolute;
  z-index:1;
  right:0;
  top:calc(50% - 22px);
}
.activities-section .indicators .slick-dots {
  padding: 0px;
  list-style-type: none;
  margin: 0;
}
.activities-section .indicators .slick-dots li {
  margin-bottom:0;
  text-align:right;
  line-height:0;
  font-size:0;
  padding-top: 17px;
  padding-bottom: 17px;
}
.activities-section .indicators .slick-dots li:before {
  text-indent: unset;
  font: normal normal bold 20px/26px PF Futura Neu;
  letter-spacing: 0px;
  color: #16E700;
  vertical-align: middle;
  margin-right: 10px;
  transition:all 0.3s ease-in-out;
}
.activities-section .indicators .slick-dots li.slick-active:before, .activities-section .indicators .slick-dots li:hover:before {
  color:#FFFFFF;
}
.activities-section .indicators .slick-dots li:nth-child(1):before {
  content:'01';
}
.activities-section .indicators .slick-dots li:nth-child(2):before {
  content:'02';
}
.activities-section .indicators .slick-dots li:nth-child(3):before {
  content:'03';
}
.activities-section .indicators .slick-dots li:nth-child(4):before {
  content:'04';
}
.activities-section .indicators .slick-dots li:nth-child(5):before {
  content:'05';
}
.activities-section .indicators .slick-dots li button {
  height:2px;
  width:82px;
  background-color:#16E700;
  text-indent:-9999px;
  border:unset;
  transition:all 0.3s ease-in-out;
}
.activities-section .indicators .slick-dots .slick-active button, .activities-section .indicators .slick-dots li:hover button, .activities-section .indicators .slick-dots li button:focus {
  width:182px;
  outline:unset;
}
.activities-section .indicators.indicators-mb {
  bottom: 0;
  top: unset;
  left: 0;
  right: 0;
}
.activities-section .indicators.indicators-mb .slick-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.activities-section .indicators.indicators-mb .slick-dots li button {
  height:2px;
  width:20px;
  background:transparent;
}
.activities-section .indicators.indicators-mb .slick-dots .slick-active button, .activities-section .indicators.indicators-mb .slick-dots li:hover button, .activities-section .indicators.indicators-mb .slick-dots li button:focus {
  width:20px;
}
.activities-section .indicators.indicators-mb .slick-dots li {
  text-align:center;
  width:47px;
}
.activities-section .indicators.indicators-mb .slick-dots li:before {
  margin-right:unset;
}
.activities-section .bg-img {
  position:absolute;
  top:0;
  left:0;
  bottom:0;
}
.activities-section .activity {
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  /*opacity:0;*/
  transition: all 1s ease-out;
}
.activities-section .bg-img {
  transition: all 1s ease-out;
}
.activities-section .activity.visible {
  opacity:1;
}
.activities-section .indicators {
  cursor:pointer;
  position:absolute;
  z-index:1;
  right:0;
  top:32%;
}
.activities-section .indicators a {
  padding-top:11px;
  padding-bottom:11px;
}
.activities-section .indicators a .no {
  font-family: 'PF Futura Neu';
  color:#16E700;
}
.activities-section .indicators a .no-line {
  height:2px;
  width:82px;
  background-color:#16E700;
  margin-left:18px;
  transition:all 0.3s ease-in-out;
}
.activities-section .indicators a.ind-active .no-line, .activities-section a:hover {
  width:182px;
}
.activities-section .indicators a.ind-active, .activities-section a:hover {
  text-decoration:none;
}
.activities-section .indicators a.ind-active .no, .activities-section a:hover .no {
  color:#FFFFFF
}
.activities-section .indicators a.ind-active .no-line, .activities-section a:hover .no-line {
  background-color:#FFFFFF
}
.activities-section .activities-slider .slick-list, .activities-section .activities-slider .slick-track, .activities-section .activities-slider .slick-slide, .activities-section .activities-slider .slick-slide > div, .activities-section .activities-slider .slick-slide > div > div {
  height:100%;
}
/*.activities-section .indicators ul {
  padding: 0px;
  list-style-type: none;
  margin: 0;
}*/
/*.activities-section .indicators ul li {
  margin-bottom:0;
  text-align:right;
  line-height:1;
  padding-top:24px;
  padding-bottom:24px;
}*/
/*.activities-section .indicators a {
  height:2px;
  width:82px;
  background-color:#FFFFFF;
  border:unset;
  display:block;
  transition:all 0.3s ease-in-out;
}*/

.projects-section {
  padding-bottom:88px;
}
.home-projects-section {
  /*background:url('/demo/page-content-lines.svg'), linear-gradient(#00c71f 0%, #00c71f 220px, transparent 220px, transparent 100%);*/
  /* new*/
  background:url('/demo/page-content-lines.svg'), transparent;
  /*end new*/
  background-repeat:no-repeat;
  background-position-x: -400%;
  background-position-y: -35%;
  transition:all 0.6s ease-in-out;
}
.home-projects-section.visible-lines {
  background-position-x: -225%;
}
.projects-section .card-columns {
  column-count:2;
}
.projects-section .card {
  border-color:transparent;
  border-radius: 0;
  background-color: transparent;
}
.projects-section .single {
  position:relative;
}
.projects-section .type3 {
  text-align:right;
}
.projects-section .type1, .projects-section .type2, .projects-section .type3, .projects-section .content {
  opacity:0;
  transform:translateY(100px);
  transition: all 0.6s ease-out;
}
.projects-section .type2 {
  transform:translateY(410px);
}
.projects-section .type3 {
  transform:translateY(240px);
}
.projects-section .content {
  transform:translateY(430px);
}
.projects-section .type1.visible, .projects-section .type2.visible, .projects-section .type3.visible, .projects-section .content.visible {
  opacity:1;
  transform:none;
}
.projects-section .type2.visible {
  transform:translateY(310px);
}
.projects-section .type3.visible {
  transform:translateY(140px);
}
.projects-section .content.visible {
  transform:translateY(330px);
}
.projects-section .caption .subtitle {
  color:#717E80;
  transition:all 0.3s ease-in-out;
}
.projects-section .caption .title, .projects-section .caption .readmore {
  color:#3C3C3C;
  transition:all 0.3s ease-in-out;
}
.projects-section .caption {
  background-color:#FFFFFF;
  position:absolute;
  right:0;
  bottom:0px;
  width:306px;
  height:215px;
  padding-left:32px;
  padding-right:32px;
  overflow:hidden;
}
.home-projects-section .caption {
  height:263px;
}
.projects-section .project-presentation {
  padding-bottom:30px;
}
.home-projects-section .caption {
  bottom:60px;
}
.projects-section .caption-content {
  position: relative;
  width: 100%;
  height: 100%;
}
.projects-section .caption .bg {
  background-color:#00C71F;
  border-radius:50%;
  position:absolute;
  width: 70px;
  height: 70px;
  left: -14%;
  top: -14%;
  transition:all 0.3s ease-in-out;
}
.inner-projects .single {
  opacity:0;
  transform:translateY(100px);
  transition:all 0.6s ease-in-out;
}
.inner-projects .single.visible {
  opacity:1;
  transform:unset;
}
.projects-section .single .caption:hover .bg {
  width: 160%;
  height: 160%;
  left: -25%;
  top: -25%;
}
.inner-projects .single .caption:hover .bg {
  height:227.7%;
  top:-60%;
}
.projects-section .single .caption:hover .subtitle, .projects-section .single .caption:hover .readmore {
  color:#FFFFFF;
}
.projects-section .content {
  padding-left:50px;
}
.projects-section .content h3 {
  margin-bottom:55px;
}
.projects-section .content a {
  font-size:22px;
  line-height:22px;
  margin-bottom:25px;
}
.projects-section .content a:hover, .projects-section .content a:focus {
  text-decoration:none;
}
.projects-section .content a:before {
  content:'';
  display:inline-block;
  vertical-align: 9px;
  background-color:#00C71F;
  transition:all 0.3s ease-in-out;
  height:2px;
  width:17px;
  margin-right:10px;
}
.projects-section .content a.extra:before {
  content:unset;
}
.projects-section .content a:hover:before {
  width:70px;
  margin-right:20px;
}
.projects-section .content a.extra .middle-ring {
  border-color:#00C71F54;
}
.projects-section .content a.extra .inner-ring {
  background-color:#00C71F;
}
.projects-section .content a.extra.main-btn .btn-text {
  top:24px;
}

.estates-section-wrapper {
  position: relative;
  overflow-x: hidden;
  padding-top:200px;
}
.estates-section {
  background-color:#b6c6c8;
  height: auto;
  padding-top: 200px;
  padding-bottom: 173px;
}
.estates-section .estates-logo {
  margin-top:47px;
}
.estates-section .tagline, .estates-section .headline, .estates-section .paragraph, .estates-section .main-btn, .estates-logo {
  opacity:0;
  transition: all 0.6s ease-out;
}
.estates-section .tagline {
  transform:translateX(-100px);
}
.estates-section .headline {
  transform:translateX(100px);
}
.estates-section .paragraph {
  transform:translateX(-100px);
}
.estates-section .main-btn {
  transform:translateY(100px);
}
.estates-section .tagline.visible, .estates-section .headline.visible, .estates-section .paragraph.visible, .estates-section .main-btn.visible, .estates-section .estates-logo.visible {
  opacity:1;
  transform:none;
}
.estates-section-wrapper .bg-img {
  position:absolute;
  top:0;
}

.contact-form-section {
  padding-bottom:144px;
}
.contact-form-section .headline, .contact-form-section .paragraph, .contact-form-section .form {
  opacity:0;
  transition:all 0.6s ease-in-out;
}
.contact-form-section .headline {
  transform:translateX(-200px);
}
.contact-form-section .paragraph {
  transform:translateX(-200px);
}
.contact-form-section .form {
  transform:translateY(200px);
}
.contact-form-section .headline.visible, .contact-form-section .paragraph.visible, .contact-form-section .form.visible {
  opacity:1;
  transform:unset;
}

.find-us-section {
  background-color:#F5F5F5;
  padding-bottom:140px;
  padding-top:108px;
}
.find-us-section .placeimg {
  padding-bottom:18px;
}

.policy-section {
  padding-bottom:157px;
}
/*h4(h6), hr(pp), h5(h6 small title), h6(h6 very small title), ul(pp), ol(pp)*/
.policy-section h4 {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 16px;
}
.policy-section hr {
  margin-top: 41px;
  margin-bottom: 21px;
}
.policy-section h5 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}
.policy-section h6 {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 12px;
}
.policy-section ul {
  list-style: none;
}
.policy-section ul, .policy-section ol {
  margin-bottom: 52px;
  padding-left: 45px;
}
.policy-section ul {
  list-style: none; /* Remove default bullets */
}
.policy-section ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #00C71F; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

/* MFP */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.with-tagline.has-effect:before {
  transform:translateX(-100px);
}
.visible .with-tagline.has-effect:before {
  transform:unset;
}
.visible.with-tagline.has-effect:before {
  transform:unset;
}

.residence-page #navigation .navbar-brand .nonsticky-logo {
  max-width:38px;
}
.residence-page #navigation .navbar-brand .sticky-logo {
  max-height:50px;
}
.estates-page .basic-text-component .left-img {
  max-width: 131px;
}

@media(min-width:768px) {
  .residence-page #navigation .navbar-brand .nonsticky-logo {
    max-width:67px;
  }
  .centered-text-component-wrapper {
    padding-top:103px;
    padding-bottom:99px;
  }
  .pt-custom {
    padding-top:64px;
  }
  .activities-section .circle-menu.circle-menu-mobile {
    display:none;
  }
  .activities-section .circle-menu.circle-menu-tablet {
    display:block;
  }
  body.inner-page {
    padding-top:117px;
  }
  .header-navbar {
    padding-left:75px;
    padding-right:75px;
    padding-top:50px;
    padding-bottom:45px;
  }
  .header-navbar .dropdown-with-lines.show {
    animation-name: slidenavAnimation;
    animation-duration:0.4s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  @keyframes slidenavAnimation {
    from {
      opacity: 0;
      /*left:100%;*/
    }
    to {
      opacity: 1;
      /*left:0;*/
    }
  }
  body, p {
    font-size:16px;
    line-height:24px;
  }
  p {
    margin-bottom:30px;
  }
  h2 {
    font-size:72px;
    line-height:80px;
    margin-bottom:18px;
  }
  h3 {
    font-size:36px;
    line-height:42px;
  }
  h4 {
    font-size:60px;
    line-height:65px;
    margin-bottom:9px;
  }
  h5 {
    font-size:24px;
    line-height:32px;
  }
  h6 {
    font-size:36px;
    line-height:42px;
    margin-bottom:22px;
  }
  .small-title {
    font-size:18px;
    line-height:24px;
    margin-bottom:14px;
  }
  .very-small-title {
    font-size:18px;
    line-height:26px;
    margin-bottom:4px;
  }
  .tagline {
    margin-left:38px;
  }
  .page-header {
    margin-bottom:90px;
    height:720px;
  }
  .page-header h4 {
    margin-bottom:90px;
  }
  .intro-section {
    padding-top:589px;
  }
  .intro-section.for-estate {
    padding-top:670px;
  }
  .estates-page .intro-section.new {
    padding-top:0px;
  }
  .about-section {
    /*new*/
    background:url('/demo/about-lines-tablet.svg') #00C71F;
    background-repeat:no-repeat;
    background-position-x: 200%;
    padding-top:260px;
    padding-bottom:280px;
    /*end new*/
  }
  .activities-section {
    /* new */
    height:1024px;
    /* end new */
  }
  .activities-section .bg-img {
    /*left:-220px;*/
    margin:auto;
    left:0;
    max-width:170px;
  }
  .home-projects-section {
    padding-bottom:60px;
    /*new*/
    transform:translateY(-125px);
    /* end new*/
  }
  .projects-section .caption .subtitle {
    font-size:11px;
    line-height:22px;
  }
  .projects-section .caption .title {
    /*font-size:18px;*/
    /*line-height:18px;*/
    /*margin-bottom:16px;*/
    font-size:17px;
    line-height:17px;
  }
  .projects-section .caption .readmore {
    font-size:11px;
    /*line-height:36px;*/
    /*margin-bottom:10px;*/
  }
  .projects-section .caption {
    /*width:172px;*/
    /*height:142px;*/
    width:184px;
    height:140px;
    bottom:22px;
  }
  .projects-section .caption.caption-bottom {
    bottom:0px;
  }
  .home-projects-section .caption {
    height:147px;
    bottom:0px;
  }
  .projects-section .caption .bg {
    left: -26%;
    top: -26%;
  }
  .home-projects-section {
    /*background:url('/demo/page-content-lines.svg'), linear-gradient(#00c71f 0%, #00c71f 120px, transparent 120px, transparent 100%);*/
    /* new */
    background:url('/demo/page-content-lines.svg'), transparent;
    /* end new */
    background-repeat:no-repeat;
    background-position-x: -400%;
    background-position-y: -35%;
  }
  .projects-section .type2 {
    transform:translateY(340px);
  }
  .projects-section .type3 {
    transform:translateY(170px);
  }
  .projects-section .content {
    transform:translateY(360px);
  }
  .projects-section .type2.visible {
    transform:translateY(170px);
  }
  .projects-section .type3.visible {
    transform:translateY(50px);
  }
  .projects-section .content.visible {
    transform:translateY(190px);
  }
  .estates-section-wrapper .bg-img {
    bottom:45%;
    right:0;
    max-width:585px;
  }
  .estates-section .estates-logo {
    max-width:352px;
  }
  .basic-text-component {
    padding-bottom:35px;
  }
  .basic-text-component.with-extra-padding {
    padding-bottom:93px;
  }
  .basic-text-component.with-tagline h6 {
    margin-bottom:50px;
  }
  .text-image-component {
    padding-bottom:69px;
    margin:auto;
  }
  .container, .container-md, .container-sm, .text-image-component {
    max-width:630px;
  }
  .with-us-component .caption {
    padding-top:0px;
  }
  .fw-presentation {
    padding-bottom:165px;
  }
  .fw-presentation .caption {
    top:25px;
  }
  .project-details .row .col-md-6 {
    padding-left:0px;
    padding-right:0px;
  }
  .image-with-caption-component p {
    margin-top:23px;
  }
  .projects-section {
    padding-bottom:72px;
  }
  .image-with-caption-component {
    padding-bottom:51px;
  }
  .policy-section {
    padding-bottom:119px;
  }
  .with-us-component .img-col {
    transform:translateY(100px);
  }
  .with-us-component .caption-col {
    transform:translateY(100px);
  }
  .with-us-component .caption-col.visible {
    transform:translateY(-72px);
  }
  .with-us-component .img-col.visible {
    transform:translateY(-72px);
  }
  .container.with-tagline:before {
    left: calc(-100% + 38px);
  }
  .container.basic-text-component.with-tagline:before {
    top: 65px;
    left: -87%;
  }
  .paginate-projects {
    /*padding-bottom:43px;*/
    border:1px solid #717E80;
    margin-bottom:43px;
  }
  .paginate-projects .left, .paginate-projects .right {
    width:calc(50% - 0.5px);
    padding-top:27px;
    padding-bottom:27px;
    cursor:pointer;
  }
  .paginate-projects a {
    text-decoration:none;
  }
  .paginate-projects .left div, .paginate-projects .right div, .paginate-projects .left svg path, .paginate-projects .right svg path {
    transition:all 0.3s ease-in-out;
  }
  .paginate-projects .left:hover div, .paginate-projects .right:hover div {
    color:#00C71F;
  }
  .paginate-projects .left:hover svg path, .paginate-projects .right:hover svg path {
    fill:#00C71F;
  }
  .paginate-projects .left div, .paginate-projects .right div {
    font-size:18px;
    line-height:24px;
  }
  .paginate-projects .left {
    padding-left: 26px;
  }
  .paginate-projects .right {
    padding-right: 26px;
  }
  .paginate-projects .left div {
    margin-left: 15%;
  }
  .paginate-projects .right div {
    margin-right: 15%;
  }
  .paginate-seperator {
    height:auto;
    width:1px;
    background-color:#707070;
    opacity:0.34;
  }
  .policy-section h4 {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 22px;
  }
  .policy-section h5 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 14px;
  }
  .policy-section h6 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px;
  }
  .footer .pp-link-with-space {
    margin-right:35px;
  }
}

@media(min-width:992px) {
  .residence-page #navigation .navbar-brand .nonsticky-logo {
    max-width:127px;
  }
  .centered-text-component-wrapper {
    padding-top:153px;
    padding-bottom:150px;
  }
  /*  body.inner-page {
      padding-top:170px;
    }*/
  /*  #navigation.sticky-navigation {
      padding-top: 20px;
      padding-bottom: 25px;
      background:#FFFFFF;
      border-bottom:1px solid #717e80;
    }
    #navigation .navbar-brand img {
      max-width:278px;
    }
    #navigation.sticky-navigation .navbar-brand img {
      max-width: 150px;
      transition:all 0.3s ease-in-out;
    }
    #navigation.sticky-navigation .nav-link {
      color:#3c3c3c;
    }
    #navigation.sticky-navigation .nav-link svg path {
      fill:rgb(113, 126, 128);
    }
    .header-navbar, .header-navbar.is-transparent {
      background-position: 120% 150%;
    }*/
  body, p {
    font-size:20px;
    line-height:34px;
  }
  p {
    margin-bottom:33px;
  }
  h2 {
    /*font-size:100px;*/
    /*line-height:100px;*/
    font-size:85px;
    line-height:85px;
    margin-bottom:10px;
    /*margin-bottom:60px;*/
  }
  h3 {
    font-size:90px;
    line-height:120px;
  }
  .activities-section h3 {
    line-height:60px;
    font-size:75px;
  }
  h4 {
    font-size:72px;
    line-height:80px;
    margin-bottom:5px;
  }
  h5 {
    font-size:36px;
    line-height:48px;
  }
  h6 {
    font-size:60px;
    line-height:80px;
    margin-bottom:41px;
  }
  .small-title {
    font-size:24px;
    line-height:32px;
    margin-bottom:10px;
  }
  .very-small-title {
    font-size:18px;
    line-height:26px;
    margin-bottom:4px;
  }
  h3.with-sub-fix {
    /*padding-top:45px;*/
    padding-top:70px;
  }
  .btn-green, .btn-brown, .btn-black {
    font-size:18px;
    line-height:24px;
  }
  .header-navbar .nav-link {
    font-size:16px;
    font-weight:bold;
  }
  .tagline {
    font-size:18px;
    margin-left:42px;
  }
  .main-btn .btn-text {
    font-size:16px;
  }
  label {
    font-size:18px;
    line-height:24px;
  }
  label.small {
    font-size:14px;
    line-height:36px;
  }
  .footer p {
    font-size:18px;
    line-height:29px;
    margin-bottom:34px;
  }
  .footer .mail a, .footer .phone a {
    font-size:18px;
    line-height:29px;
  }
  .footer .copy {
    font-size:12px;
    line-height:24px;
  }
  .footer .pp-link {
    font-size:12px;
    line-height:24px;
  }
  .footer-navbar a {
    font-size:14px;
    line-height:24px;
  }
  .page-header h4 {
    margin-bottom:94px;
  }
  .inline-words .word {
    font-size:30px;
  }
  .projects-section .caption .subtitle {
    color:#717E80;
    font-size:16px;
    line-height:22px;
  }
  .projects-section .caption .title {
    /*font-size:32px;*/
    /*font-size:24px;*/
    /*line-height:34px;*/
    font-size:20px;
    /*line-height:30px;*/
    line-height:24px;
    margin-bottom:10px;
  }
  .home-projects-section .caption .title {
    font-size:32px;
    line-height:34px;
  }
  .projects-section .caption .readmore {
    /*font-size:16px;*/
    font-size:13px;
    /*line-height:36px;*/
    /*margin-bottom:18px;*/
    /*margin-bottom:10px;*/
  }
  .home-projects-section.caption .readmore {
    font-size:16px;
    margin-bottom:18px;
  }
  .header-navbar {
    padding-left:50px;
    padding-right:50px;
    padding-top:41px;
    padding-bottom:47px;
  }
  .header-navbar .navbar-nav .nav-link {
    padding-top:8px;
    padding-bottom:5px;
  }
  .header-navbar .navbar-nav .nav-link.main-link, .header-navbar .navbar-nav .nav-link.estates-link {
    padding-left:22px;
    padding-right:22px;
  }
  .footer-navbar .navbar-nav .nav-link, .footer-navbar .navbar-nav .nav-circle {
    padding-left: 12px;
    padding-right: 12px;
  }
  .projects-section .caption {
    width:306px;
    /*height:215px;*/
    height:145px;
    bottom:0px;
    padding-left:25px;
    padding-right:25px;
  }
  .projects-section .caption-content {
    padding-bottom:5px;
  }
  .projects-section .caption.caption-bottom {
    bottom:0px;
  }
  .home-projects-section .caption {
    height:263px;
    bottom:61px;
  }
  .projects-section .caption .bg {
    left: -14%;
    top: -14%;
  }
  .home-projects-section {
    /*background:url('/demo/page-content-lines.svg'), linear-gradient(#00c71f 0%, #00c71f 220px, transparent 220px, transparent 100%);*/
    /* new */
    background:url('/demo/page-content-lines.svg'), transparent;
    /* end new */
    background-repeat:no-repeat;
    background-position-x: -400%;
    background-position-y: -35%;
  }
  .projects-section .type2 {
    transform:translateY(410px);
  }
  .projects-section .type3 {
    transform:translateY(240px);
  }
  .projects-section .content {
    transform:translate(100px, 330px);
  }
  .projects-section .type2.visible {
    transform:translateY(310px);
  }
  .projects-section .type3.visible {
    transform:translateY(140px);
  }
  .projects-section .content.visible {
    transform:translate(0, 330px);
  }
  .estates-section .estates-logo {
    max-width:100%;
  }
  .basic-text-component {
    padding-bottom:121px;
  }
  .basic-text-component.with-extra-padding {
    padding-bottom:121px;
  }
  .pr-lg-normal {
    padding-right:30px;
  }
  .pl-lg-normal {
    padding-left:30px;
  }
  .text-image-component {
    padding-bottom:112px;
  }
  .text-image-component {
    max-width:100%;
  }
  .container, .container-lg, .container-md, .container-sm {
    max-width:960px;
  }
  .with-us-component .container {
    max-width:960px;
  }
  .fix-md-order {
    order:unset;
  }
  .with-us-component .caption {
    padding-top:0px;
  }
  .fw-presentation {
    padding-bottom:101px;
  }
  .fw-presentation .caption {
    top:75px;
  }
  .project-details .row .col-md-6 {
    padding-left:15px;
    padding-right:15px;
  }
  .image-with-caption-component p {
    margin-top:43px;
  }
  .projects-section {
    padding-bottom:88px;
  }
  .home-projects-section {
    /*padding-top:100px;*/
    padding-bottom:330px;
    overflow:hidden;
  }
  .image-with-caption-component {
    padding-bottom:90px;
  }
  .policy-section {
    padding-bottom:157px;
  }
  .with-us-component .img-col {
    transform:translateY(100px);
  }
  .with-us-component .caption-col {
    transform:translateY(100px);
  }
  .with-us-component .caption-col.visible {
    transform:translateY(-45px);
  }
  .with-us-component .img-col.visible {
    transform:translateY(-122px);
  }
  .container.with-tagline:before {
    top: 15px;
    left: calc(-100% + 42px);
  }
  .container.with-tagline.offset1:before {
    left:calc(-100% + 42px + 8.333%);
  }
  .container.with-tagline.offset2:before {
    left:calc(-100% + 42px + 16.667%);
  }
  .container.basic-text-component.with-tagline:before {
    top: 105px;
    left: -93%;
  }
  /*  .activities-section .circle-menu {
      left:-1325px;
    }*/
  .activities-section .indicators {
    /*top:70%;*/
    top: calc(50% - 22px);
    transform: translateY(-50%);
  }
  /*  .activities-section .indicators .slick-dots li button, .activities-section .indicators .slick-dots .slick-active button, .activities-section .indicators .slick-dots li button:focus, .activities-section .indicators .slick-dots li:hover button {
      width:20px;
    }*/
  .scroll-to-top {
    bottom:130px;
    right:95px;
  }
  .dropdown:hover>.dropdown-menu {
    display: block;
    animation-name: slidenavAnimation;
    animation-duration:.4s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }
  .paginate-projects .left div {
    margin-left: 29.5%;
  }
  .paginate-projects .right div {
    margin-right: 29.5%;
  }
  .policy-section h4 {
    font-size: 60px;
    line-height: 80px;
    margin-bottom: 41px;
  }
  .policy-section h5 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  .policy-section h6 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px;
  }
  .btn-brown {
    font-size:18px;
    line-height:24px;
  }
}

@media(min-width:1024px) {
  h3 span {
    line-height:50px;
  }
  .headline span {
    line-height:100px;
  }
}

@media(min-width:1200px) {
  .activities-section .circle-menu.circle-menu-tablet {
    display:none;
  }
  .activities-section .circle-menu.circle-menu-desktop {
    display:block;
  }
  .activities-section .bg-img {
    left:-220px;
    max-width:unset;
  }
  .d-lg-none-custom {
    display:none !important;
  }
  .lg-hide {
    display:none;
  }
  .d-lg-flex-custom {
    display:flex;
  }
  .pt-custom {
    padding-top:113px;
  }
  .container, .container-lg, .container-md, .container-sm, .container-xl, .with-us-component .container {
    max-width:1140px;
  }
  .intro-section {
    padding-top:25%;
  }
  .intro-section.for-estate {
    padding-top:30%;
  }
  .estates-page .intro-section.new {
    padding-top:0px;
  }
  .about-section {
    /*new*/
    background:url('/demo/about-lines.svg') #00C71F;
    background-repeat:no-repeat;
    background-position-x: 200%;
    padding-top:191px;
    padding-bottom:164px;
    /*end new*/
  }
  .activities-section {
    /* new */
    height:1311px;
    /* end new */
  }
  .activities-section .bg-img {
    left:0;
    margin:unset;
  }
  body.inner-page {
    padding-top:170px;
  }
  #navigation.sticky-navigation {
    padding-top: 20px;
    padding-bottom: 25px;
    background:#FFFFFF;
    border-bottom:1px solid #717e80;
  }
  .nonsticky-logo {
    display:block;
  }
  .sticky-logo {
    display:block;
  }
  #navigation .nonsticky-logo {
    opacity:1;
    visibility:visible;
    width:100%;
    height:auto;
    transition:all 0.3s ease-in-out;
  }
  #navigation .sticky-logo {
    opacity:0;
    visibility:hidden;
    width:0px;
    height:0px;
    transition:all 0.3s ease-in-out;
  }
  #navigation.sticky-navigation .nonsticky-logo {
    visibility:hidden;
    opacity:0;
    width:0px;
    height:0px;
  }
  #navigation.sticky-navigation .sticky-logo {
    visibility:visible;
    opacity:1;
    width:100%;
    height:auto;
  }
  #navigation .navbar-brand img {
    max-width:277px;
  }
  .residence-page #navigation .navbar-brand .nonsticky-logo {
    max-width:127px;
  }
  .residence-page #navigation {
    align-items:flex-start;
  }
  #navigation.sticky-navigation .navbar-brand img {
    max-width: 150px;
    transition:all 0.3s ease-in-out;
  }
  #navigation.sticky-navigation .nav-link {
    color:#3c3c3c;
    font-size:15px;
  }
  #navigation.sticky-navigation .nav-link svg path {
    fill:rgb(113, 126, 128);
  }
  .header-navbar, .header-navbar.is-transparent {
    background-position: 120% 150%;
  }
  /* start george css */
  .projects-section .caption {
    width: 280px;
    /*height: 180px;*/
    height:160px;
  }
  .home-projects-section .caption {
    width: 300px;
    height: 205px;
  }
  .projects-section .caption .readmore {
    margin-bottom: 0px;
    line-height: 28px;
  }
  .projects-section .caption .title {
    /*font-size: 22px;*/
    /*line-height: 28px;*/
    font-size:18px;
    line-height:20px;
  }
  .home-projects-section .caption .title {
    /*font-size: 28px;
    line-height: 32px*/
    font-size:26px;
    line-height:30px;
  }
  .inner-page .basic-text-component-effect {
    overflow:unset;
  }
  .fw-presentation .caption {
    min-width:250px;
  }
}

@media(min-width:1200px) and (max-width:1300px) {
  .activities-section .offset-lg-5 {
    margin-left:45%;
  }
}

@media(min-width:1365px) {
  h2 {
    margin-bottom: 60px;
  }
}

@media(min-width:1400px) {
  .header-navbar {
    padding-left:60px;
    padding-right:60px;
  }
}

@media(min-width:1600px) {
  .activities-section h3 {
    /*font-size:90px;*/
  }
  .estates-section-wrapper .bg-img {
    right:0;
    max-width:unset;
  }
}

@media(min-width:1840px) {
  .activities-section .bg-img {
    max-width:unset;
    left:0px;
    margin:unset;
  }
  .activities-section .indicators {
    top:32%;
    transform:unset;
  }
  .activities-section .indicators .slick-dots li button {
    width:82px;
  }
  .activities-section .indicators .slick-dots .slick-active button, .activities-section .indicators .slick-dots li button:focus, .activities-section .indicators .slick-dots li:hover button {
    width:182px;
  }
  .activities-section .circle-menu {
    left: -1070px;
    top: -420px;
  }
}

@media(max-width:1199px) {
  .menu-active .page-header, .menu-active .footer, .menu-active .page-content, .menu-active .slider-wrapper, .menu-active .about-section, .menu-active .activities-section, .menu-active .home-projects-section, .menu-active .estates-section {
    opacity: 0.6;
  }
  .footer {
    z-index:1;
  }
  #navigation.sticky-navigation .navbar-brand img {
    opacity:0;
    visibility:hidden;
    pointer-events:none;
  }
  .header-navbar {
    /*    background:url('/demo/burger-bg.svg') #FFFFFF;
        background-repeat:no-repeat;
        background-position: 120% 150%;*/
    z-index:1;
    padding-top:16px;
    padding-bottom:19px;
  }
  .header-navbar.is-transparent {
    /*    background:url('/demo/burger-bg.svg') transparent;
        background-repeat:no-repeat;
        background-position: 120% 150%;*/
  }
  .header-navbar .nav-link {
    color:#FFFFFF;
  }
  .header-navbar .nav-link svg path {
    fill:#FFFFFF;
  }
  .navbar-toggler {
    z-index:1;
    position:absolute;
    top:15px;
    right:20px;
    padding:0;
    border:unset;
    /*overflow:hidden;*/
  }
  .navbar-toggler:focus {
    outline:unset;
  }
  .burger-bg {
    /*    position:absolute;
        top:0;
        right:0;
        transform:translate(45%, -45%);
        overflow:hidden;*/
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    width: 120px;
    height: 120px;
  }
  .burger-bg svg {
    position: absolute;
    top: -50px;
    right: -50px;
    overflow:hidden;
  }
  .burger-bg svg circle {
    overflow:hidden;
  }
  .navbar-collapse {
    background-color:#00C71F;
    min-height:100vh;
    position:absolute;
    /*width:81.25%;*/
    width:0;
    /*left:100%;*/
    margin-left:18.75%;
    right:0;
    top:0;
    transition:all 0.35s ease;
  }
  .navbar-collapse.show {
    /*left:18.75%;*/
    width:81.25%;
  }
  .navbar-collapse .navbar-nav {
    padding-left:15px;
    padding-top:58px;
  }
  .navbar-collapse .dropdown-toggle {
    align-items:center;
  }
  .seperator-item {
    display:none;
  }
  .mb-hide {
    display:none;
  }
  .mb-ul-nav {
    list-style-type:none;
    padding-left:15px;
    margin-bottom:0px;
  }
  .navbar-nav .nav-link {
    display:inline-flex;
  }
  .navbar-nav .nav-link {
    padding-left: 15px;
    padding-right: 15px;
  }
  .mb-ul-nav li {
    margin-right:34px;
    margin-bottom:0px;
  }
  .mb-ul-nav.social-nav li {
    margin-right:29px;
  }
  .header-navbar .nav-link.estates-link {
    border-color:#FFFFFF45;
  }
  .header-navbar .lang-nav {
    border-top:1px solid #FFFFFF45;
    border-bottom:1px solid #FFFFFF45;
    padding-top:0px;
    padding-bottom:0px;
  }
  .header-navbar .social-nav {
    padding-top:0px;
  }
  .header-navbar .dropdown-menu.dropdown-with-lines {
    max-width: 100%;
    background: transparent;
    border: unset;
    box-shadow: unset !important;
    padding-top:0px;
    margin-top:-10px;
    padding-bottom:19px;
  }
  .header-navbar .dropdown-with-lines .dropdown-item:before {
    background-color:#FFFFFF;
    width:35px !important;
    left:-15px !important;
  }
  .header-navbar .dropdown-with-lines .dropdown-item {
    padding-left:27px;
    padding-top:14px;
    padding-bottom:14px;
    color:#FFFFFF;
  }
}

@media(max-width:1365px) {
  /*  .activities-section {
      overflow:hidden;
    }*/
}
@media(min-width:992px) and (max-width:1119px) {
  .header-navbar {
    background-position: 109% 2500%;
  }
  .header-navbar.is-transparent {
    background-position: 109% 2500%;
  }
}

@media(max-width:767px) {
  .estate-details-wrapper .left {
    order:2;
  }
  .estate-details-wrapper img {
    margin-top:22px;
  }
  .mb-ul-nav.social-nav li, .mb-ul-nav li {
    margin-right:0;
  }
  .navbar-nav .nav-link {
    padding-top:17px;
    padding-bottom:17px;
  }
  .header-navbar .lang-nav {
    margin-top:17px;
  }
  .home-projects-section .content {
    opacity:1;
    transform:unset;
  }
  .activities-section p.text-white {
    padding-left:0px;
    font-size:14px;
  }
  .activities-section .main-btn {
    margin-left:80px;
    width:auto;
  }
  .activities-section .circle-menu {
    top: -450px;
    left: -1565px;
  }
  .estates-section-wrapper .bg-img {
    max-width:160px;
    top:0;
  }
  .estates-section {
    padding-bottom:30px;
    overflow:hidden;
  }
  .estates-mobile-logo {
    position: absolute;
    top: 150px;
    left: 0;
    right: 0;
  }
/*  .intro-section {
    padding-bottom:100px;
  }*/
  .intro-section h4 {
    margin-bottom:12px;
  }
  .play svg {
    max-width:54px;
  }
  h3 span {
    margin-top:unset;
  }
  .projects-section .content {
    padding-left:15px;
  }
  .navbar-brand img {
    max-width:167px;
  }
  .header-navbar {
    padding-left:15px;
    padding-right:15px;
  }
  .activity-content {
    /*padding-left:75px;*/
    padding-left: 15px;
    padding-right: 15px;
  }
  .about-section {
    overflow:hidden;
    background:#00c71f;
  }
  .main-btn .btn-text {
    top:25px;
  }
  p.text-white {
    margin-bottom:10px;
  }
  .home-projects-section .caption {
    width:230px;
    height:198px;
    bottom:32px;
  }
  .home-projects-section {
    /*background:url('/demo/page-content-lines.svg'),linear-gradient(#00c71f 0,#00c71f 90px,transparent 90px,transparent 100%);*/
    background:linear-gradient(to bottom, #01c71f 90px, transparent 91px);
  }
  .projects-carousel .slick-dots {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0;
    padding-top: 20px;
  }
  .projects-carousel .slick-dots li {
    margin-bottom: 0px;
  }
  .projects-carousel .slick-dots button {
    text-indent: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #717E80;
    padding: 0;
    margin-left: 15px;
    margin-right: 15px;
    border-color: transparent;
  }
  .projects-carousel .slick-dots li.slick-active button, .projects-carousel .slick-dots li:hover button {
    background:#B6C6C8;
  }
  .projects-carousel .slick-dots li button:focus {
    outline:unset;
  }
  .estates-section-wrapper .bg-img {
    right:0px;
  }
  .estates-section-wrapper {
    padding-top:75px;
  }
  .footer {
    padding-top:37px;
    padding-bottom:30px;
    background:#3c3c3c;
  }
  .footer .left {
    padding-bottom:58px;
  }
  .footer .socials {
    padding-top:53px;
    padding-bottom:30px;
  }
  .footer .copycol {
    /*padding-bottom:14px;*/
  }
  .footer svg {
    max-height:18px;
  }
  .footer h5 {
    margin-top:20px;
  }
  .page-header {
    margin-bottom:60px;
  }
  .page-header h4 {
    margin-bottom:52px;
  }
  .page-header .circle {
    left:15px;
  }
  .page-header .circle svg {
    width: 72px;
    height: 72px;
  }
  .inline-words {
    flex-direction: column;
  }
  .inline-words .circle {
    margin-top:26px;
    margin-bottom:22px;
  }
  .basic-text-component {
    padding-bottom:40px;
    overflow:hidden;
  }
  .basic-text-component:last-of-type {
    padding-bottom:75px;
  }
  .basic-text-component.estates-text-component:last-of-type {
    padding-bottom:20px;
  }
  .basic-text-component h6 {
    margin-bottom:38px;
  }
  .basic-text-component h6.small-title {
    margin-bottom:11px;
  }
  .container.basic-text-component.with-tagline:before {
    top:50px;
  }
  .container.basic-text-component.with-tagline:before {
    left:-76%;
  }
  .text-image-component {
    padding-bottom:54px;
  }
  .with-us-component .caption {
    text-align:center;
    padding-top:32px;
  }
  .with-us-component .caption h5, .with-us-component .caption p {
    text-align:left;
  }
  .with-us-component .caption-col.visible {
    transform:translateY(-77px);
  }
  .scroll-to-top {
    bottom:110px;
  }
  .scroll-to-top svg {
    height:64px;
  }
  .form-group .btn-green, .form-group .btn-brown, .form-group .btn-black {
    width:100%;
  }
  .contact-form-section {
    padding-bottom:63px;
  }
  .contact-form-section .form-group:last-of-type {
    margin-bottom:0px;
  }
  .find-us-section h5 {
    font-size:36px;
    line-height:18px;
  }
  .find-us-section p {
    margin-bottom:16px;
  }
  .find-us-section .col-md-6:first-of-type {
    padding-bottom:72px;
  }
  .find-us-section {
    padding-top:80px;
    padding-bottom:104px;
  }
  ol.pp, ul.pp {
    padding-left:25px;
    margin-bottom:31px;
  }
  .policy-section {
    padding-bottom:11px;
  }
  .fw-presentation .caption {
    padding-top: 24px;
    padding-bottom: 20px;
    top: 182px;
    right:15px;
  }
  .fw-presentation .main-btn {
    margin-top:116px !important;
    margin-left:15px;
  }
  .fw-presentation {
    padding-bottom:71px;
  }
  .fw-presentation .caption h5 {
    line-height:30px;
  }
  .projects-section .caption {
    width:230px;
    /*height:198px;*/
    height:172px;
    bottom:32px;
  }
  .projects-section .project-presentation {
    padding-bottom:20px;
  }
  .projects-section {
    padding-bottom:45px;
  }
  .project-details .seperator-line {
    display:none;
  }
  .project-details-component .right {
    border-left:1px solid #B7BEBF;
    border-right:1px solid #B7BEBF;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 15px;
    margin-right: 15px;
    padding-top:27px;
    padding-bottom:9px;
  }
  .item {
    margin-bottom:17px;
  }
  .project-details-component {
    padding-bottom:55px;
  }
  .image-with-caption-component.splitted .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333%;
    flex: 0 0 58.333%;
    max-width: 58.333%;
  }
  .image-with-caption-component.splitted .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.667%;
    flex: 0 0 41.667%;
    max-width: 41.667%;
  }
  .image-with-caption-component p {
    padding-top:13px;
  }
  .image-with-caption-component {
    padding-bottom:49px;
  }
  .carousel-mfp svg {
    width:40px;
    height:40px;
  }
  .single-project-nav {
    width:100px;
    padding-top: 12px;
    padding-bottom: 9px;
    bottom:-30px;
  }
  .single-project-nav .seperator {
    margin-left:20px;
    margin-right:20px;
    height:25px;
  }
  .single-project-nav .slick-arrow svg {
    width:8px;
    height:14px;
  }
  .single-project-section .spacer {
    height:60px;
  }
  .paginate-projects {
    border-top: 1px solid #717E8034;
    border-bottom: 1px solid #717E8034;
    padding-top:19px;
    padding-bottom:18px;
    padding-left:15px;
    padding-right:15px;
    position:relative;
    font-size:13px;
    margin-bottom:43px;
  }
  .paginate-projects:after {
    content:'';
    height:100%;
    width:1px;
    background:#717E8034;
    position:absolute;
    left:1px;
    right:0;
    top:0;
    bottom:0;
    margin:auto;
  }
  .paginate-seperator {
    display:none;
  }
  .paginate-projects .left, .paginate-projects .right {
    width:50%;
  }
  .paginate-projects .left svg {
    margin-right:9px;
    width:10px;
    height:9.75px;
  }
  .paginate-projects .right svg {
    margin-left:9px;
    width:10px;
    height:9.75px;
  }
  .inner-projects .single {
    transform:unset;
    opacity:1;
  }
  .projects-section.inner-projects {
    padding-bottom:25px;
  }
  .no-p-mb {
    padding-left:0px;
    padding-right:0px;
  }
  .image-with-caption-component {
    padding-bottom:64px;
  }
  .image-with-caption-component.non-splitted .no-p-mb {
    padding-bottom:22px;
  }
  .image-with-caption-component.non-splitted .col-md-7 {
    order:1;
  }
  .image-with-caption-component.non-splitted .col-md-5 {
    order:2;
  }
  /*  .container, .container-fluid {
      padding-left:20px;
      padding-right:20px;
    }
    .row {
      margin-left:-20px;
      margin-right:-20px;
    }*/
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-left:20px;
    padding-right:20px;
  }
}

/* POPUPS */
.modal-dialog.modal-xxl {
  max-width:100%;
  margin:0;
}
.modal-dialog.modal-xxl .modal-content {
  height:100%;
  border:unset;
  border-radius:unset;
}
#carouselModal {
  padding-right:0px !important;
}
.modal-close {
  font-size:16px;
  line-height:1;
  color:#CF814A;
  text-decoration:unset;
  position:absolute;
  top: 5px;
  right: 15px;
  z-index: 1;
}
.modal-close:hover {
  text-decoration:unset;
}
@media(min-width:576px) {
  .modal-dialog.modal-xxl {
    max-width:100%;
    margin:0;
  }
}
.sticky-side-link {
  position: fixed;
  top: 50%;
  transform: rotateZ(90deg);
  right: -35px;
  color:#3C3C3C;
  border-bottom:1px solid #3C3C3C;
  font-size:16px;
  line-height:1;
  padding-bottom:5px;
  font-family: 'Open Sans', sans-serif;
  font-weight:800;
  text-decoration:unset;
  opacity:0;
  visibility:hidden;
  z-index:-1;
  transition:all 0.3s ease-in-out;
}
.sticky-side-link.sticky-link {
  z-index:1;
  visibility:visible;
  opacity:1;
}
.sticky-side-link:hover {
  text-decoration:unset;
}
.estates-burger-bg svg circle {
  fill:#CD7F45;
}
@media(max-width:1199px) {
  .estates-navbar-collapse {
    background-color:#CD7F45;
  }
}

/* ESTATES ONLY */
/*.estates-page .navbar-brand img {
  padding-top:8%;
  padding-bottom:8%;
}*/
.mouse-pointer, .mouse-pointer * {
  cursor:pointer;
}
.with-arrow {
  max-width:187px;
}
.with-arrow .arrow-r {
  line-height:0.2;
}
.with-arrow .arrow-r svg path {
  fill:white;
}
.page-header.estates-header {
  background:url('/demo/estates-header.jpg'), linear-gradient(to top, #00000073, transparent);
  background-repeat:no-repeat;
  background-size:cover;
}
.estates-page .tagline {
  position:relative;
  color:#CD7F45;
}
.estates-page .tagline:before {
  content:'';
  background-color:#CD7F45;
  width:28px;
  height:2px;
  position:absolute;
  left:-42px;
  top:50%;
}
.project-header .tagline {
  position:relative;
}
.project-header .tagline a {
  text-decoration:unset !important;
}
.project-header .tagline:before {
  content:'';
  background-color:#01c71f;
  width:28px;
  height:2px;
  position:absolute;
  left:-42px;
  top:50%;
}
.estates-page .container.with-tagline:before {
  content:unset;
}
.estates-page .container.with-tagline .tagline:before {
  width:100%;
  left:calc(-100% - 15px);
}
.estate-details .project-details .seperator-line {
  top:0;
  height:50%;
}
.estate-details.project-details-component .left, .estate-details.project-details-component .right {
  opacity:1;
  transform:unset;
}
.estate-details-wrapper {
  background:#F5F5F5;
  /*padding-top:63px;*/
  padding-bottom:45px;
}
.mfp-video {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
}
.video-component, .estates-carousel-component {
  padding-top:32px;
  padding-bottom:47px;
}
.design-line-wrapper {
  background:#F5F5F5;
  padding-top:63px;
}
.design-line-wrapper .basic-text-component {
  padding-bottom:0;
}
.footer-logo-estates {
  margin-bottom:34px;
}
.interior-section {
  /*padding-top:43px;*/
  padding-bottom:43px;
}
.interior-content {
  margin-bottom:0;
}
.interior-caption {
  font-size:16px;
  line-height:36px;
  /*font-weight:bold;*/
  /*font-family: 'PF Futura Neu';*/
  font-family: 'Roboto', sans-serif;
}
.interior-content {
  font-size:16px;
  line-height:24px;
}
.interior-caption, .interior-content-slick {
  max-width:100%;
}
.interior-row {
  padding-bottom:34px;
}
.amenities-wrapper {
  background-color:#F5F5F5;
  padding-top:45px;
  padding-bottom:58px;
}
.amenities-wrapper .basic-text-component {
  padding-bottom:0;
}
.amenities-list {
  list-style-position: inside;
  list-style-type: none;
  padding-left: 0px;
  margin-bottom:40px;
}
.amenities-list li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #CD7F45; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 22px;
  font-size: 36px;
  margin-left: 15px;
}
.amenities-list li {
  border-top:1px solid #B9BFC0;
  text-transform: uppercase;
  padding-top:16px;
  padding-bottom:11px;
  padding-left:0px;
}
.amenities-list li:last-of-type {
  border-bottom:1px solid #B9BFC0;
}
.estates-page .contact-form-section *, .estates-page .footer .socials {
  transform:unset;
  opacity:1;
}
.estates-page .contact-form-section {
  padding-top:38px;
  padding-bottom:51px;
}
.estates-carousel .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: auto;
  padding-top:14px;
}
.estates-carousel .slick-dots li {
  margin:0;
  width:calc((100% / 8 - 24px));
  height:3px;
  margin-left:6px;
  margin-right:6px;
}
.estates-carousel .slick-dots li button {
  text-indent: -9999px;
  border:unset;
  border-radius:0;
  width:100%;
  height:100%;
  padding:0;
  background-color:#717E80;
  outline: unset;
}
.estates-carousel .slick-dots li button:focus {
  outline:unset;
}
.estates-carousel .slick-dots li.slick-active button, .estates-carousel .slick-dots li:hover button {
  background-color:#CD7F45;
}
.estates-carousel-wrapper {
  background-color:#F5F5F5;
}
.estates-carousel-component .left, .estates-carousel-component .right {
  cursor:pointer;
  position: absolute;
  top: 27%;
  transform: translateY(-50%);
  width:40px;
  height:40px;
}
.estates-carousel-component .left svg, .estates-carousel-component .right svg {
  width:100%;
  height:auto;
}
.estates-carousel-component .left {
  left: 15px;
}
.estates-carousel-component .right {
  right:15px;
}
.estates-carousel-component .left:hover svg rect, .estates-carousel-component .right:hover svg rect {
  opacity:1;
}
.estates-carousel-component .left:hover svg path, .estates-carousel-component .right:hover svg path {
  fill:#cd7f45;
}
.estates-carousel-component p {
  margin-top:25px;
}
.word-carousel-nav {
  max-width:100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;
}
.word-carousel-nav .navlink {
  cursor:pointer;
  width:calc(100% / 3);
  padding-bottom:12px;
  border-bottom:1px solid #CD7F45;
}
.word-carousel-nav .navlink.boldlink p {
  font-weight:600;
}
.word-carousel-nav .navlink:nth-child(1) {
  margin-right:15px;
}
.word-carousel-nav .navlink:nth-child(2) {
  margin-right:15px;
  margin-left:15px;
}
.word-carousel-nav .navlink:nth-child(3) {
  margin-left:15px;
}
.word-carousel .slick-slide img {
  height:276px;
  object-fit:cover;
}
.estates-text-component {
  padding-top:60px;
  padding-bottom:20px;
}
.sustainability + .estates-text-component-wrapper {
  background-color:#f5f5f5;
}
.estate-content .contact-form-section-wrapper {
  background-color:#F5F5F5;
}
.estate-content .slider-wrapper, .estate-content .slider-wrapper-new {
  margin-bottom:50px;
}
.cta-component {
  background:#CD7F45;
  padding-top:69px;
  padding-bottom:79px;
}
.plans-section {
  background:#F5F5F5;
  padding-top:54px;
  padding-bottom:52px;
}
.plans-section .basic-text-component {
  padding-bottom:0px;
}
.estates-page .table tr td {
  padding-top:16px;
  padding-bottom:11px;
}
.estates-page .table tr td:first-of-type {
  text-transform: uppercase;
  padding-left:35px;
}
.estates-page .table tr td:last-of-type {
  padding-right:30px;
  text-align:right;
}
.estates-page .table td {
  border-color:#B9BFC0;
}
.estates-page .table tr:last-of-type {
  border-bottom:1px solid #B9BFC0;
}
.estates-page .nav-tabs {
  border:unset;
}
.estates-page .nav-tabs .nav-item.show .nav-link, .estates-page .nav-tabs .nav-link.active, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover, .nav-tabs .nav-link {
  border:unset;
  background:transparent;
  color:#3C3C3C;
  border-radius:0;
  border-bottom:1px solid #B6C6C8;
}
.estates-page .nav-tabs .nav-item.show .nav-link, .estates-page .nav-tabs .nav-link.active, .estates-page .nav-tabs .nav-item .nav-link:hover {
  font-weight:bold;
  color:#3C3C3C;
}
.estates-page .nav-tabs .nav-item {
  margin-left:15px;
  margin-right:15px;
}
.estates-page .tab-content {
  padding-top:38px;
  padding-bottom:42px;
}

.form-check-inline {
  align-items:start;
}
.form-check-inline .form-check-input {
  margin-top:0.3rem;
}
.form-check-inline label.small {
  line-height:24px;
}
.branch p a {
  text-decoration:unset;
  color:#C3D0D2;
}
.contact-branches p a {
  color:#717e80;
}
.branch p a:hover {
  color:#FFFFFF;
  text-decoration:unset;
}
.contact-branches p a:hover {
  color:#717e80;
}
.contact-branches a:hover {
  text-decoration:unset;
}
.cat-nav {
  padding-top:30px;
  padding-bottom:50px;
}
.cat-nav a, .cat-nav .a {
  font: normal normal bold 18px/22px PF Futura Neu;
  letter-spacing: 0px;
  color: #3C3C3C;
  text-decoration: unset;
  transition:all 0.3s ease-in-out;
  text-transform: uppercase;
}
.cat-nav .dropdown-toggle {
  width:100%;
  display:block;
  border-radius:0;
  border: 1px solid #DEDEDE;
  padding-top:17px;
  padding-bottom:17px;
  padding-left:21px;
  padding-right:21px;
}
.cat-nav .dropdown-menu {
  border-radius:0;
  width:100%;
  min-width:unset;
  padding-top:0px;
  padding-bottom:0px;
}
.cat-nav .dropdown-menu a {
  padding-top:17px;
  padding-bottom:17px;
  padding-left:21px;
  padding-right:21px;
}
.estates-page .parallax-bg {
  height:136px;
}
.fade-images {
  max-width:100%;
}
.price-breakdown {
  padding-bottom:60px;
}
.price-breakdown .very-small-title {
  font-weight:400;
  font-family: 'Roboto', sans-serif;
  margin-bottom:11px;
}
.price-breakdown .left {
  font-weight:bold;
}
.price-breakdown .item {
  border-top:1px solid #b6c6c8;
  padding-top:10px;
  padding-bottom:10px;
  margin-bottom:0px;
  padding-left:0px;
  padding-right:0px;
}
.price-breakdown .item:last-of-type {
  border-bottom:1px solid #b6c6c8;
}
.price-breakdown .table-wrapper {
  padding-left: 15px;
  padding-right: 15px;
}
.sustainability {
  padding-top:50px;
  padding-bottom:70px;
}
/*.slider-wrapper-new .slider .slick-slide img {
  transform:matrix(1, 0, 0, 1, 0, 0);
  transition:opacity 500ms ease-in-out 0s, transform 10s linear 0s;
}
@keyframes movingImage {
  from {
    transform:matrix(1, 0, 0, 1, 0, 0);
  }
  to {
    transform:matrix(1, 0, 0, 1, -100, 0);
  }
}
.slider-wrapper-new .slider .slick-current img {
  transform:matrix(1, 0, 0, 1, -100, 0);
}*/
@keyframes movingImage {
  from {
    transform:matrix(1, 0, 0, 1, 0, 0);
  }
  to {
    transform:matrix(1, 0, 0, 1, -200, 0);
  }
}
.slider-wrapper-new .slider .slick-slide img {
  width:150% !important;
  max-width:unset !important;
}
.slider-wrapper-new .slider .slick-moving img {
  animation-name:movingImage;
  animation-duration: 14s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  /*animation-fill-mode: forwards;*/
  animation-timing-function: linear;
}
.slider-wrapper-new .logo {
  position:absolute;
  left:0;
  right:0;
  bottom:67px;
  margin:auto;
  max-width:300px;
}
.list-component {
  padding-top:45px;
  padding-bottom:50px;
}
.list-component .heading {
  font-size:18px;
  line-height:30px;
  margin-bottom:16px;
  font-family: 'PF Futura Neu';
}
/*.list-component ul {
  padding-left: 0px;
  list-style-position: inside;
  margin-top:0px;
  margin-bottom:17px;
}
.list-component li {
  font-size:14px;
  line-height:24px;
  margin-bottom:0px;
}
.list-component li:marker {
  margin-right:5px;
}*/
.list-component ul {
  list-style-position: inside;
  list-style-type: none;
  padding-left: 0px;
  margin-bottom:17px;
}
.list-component li {
  font-size:14px;
  line-height:24px;
  margin-bottom:0px;
  display: flex;
}
.list-component li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #CD7F45; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 22px;
  font-size: 30px;
  margin-left: 0px;
}
.word-carousel-heading {
  font-size: 18px;
}

@media(min-width:768px) {
  .modal-dialog.modal-xxl .modal-content {
    height:100vh;
  }
  .modal-close {
    top:43px;
    right:48px;
  }
  .list-component {
    padding-top:50px;
    padding-bottom:50px;
  }
  .list-component ul {
    margin-bottom:0px;
  }
  .word-carousel .slick-slide img {
    height:373px;
  }
  .word-carousel-words {
    padding-top:35px;
  }
  .estate-content .slider-wrapper, .estate-content .slider-wrapper {
    margin-bottom:90px;
  }
  .cat-nav a {
    font: normal normal bold 11px/11px PF Futura Neu;
  }
  .cat-nav a:hover {
    color: #00C71F;
  }
  .cat-nav a.current {
    color: #C9C9C9;
  }
  .cat-nav .seperator-cat-nav {
    font-size:13px;
    font-family: 'Open Sans', sans-serif;
    color:#C9C9C9;
    font-weight:300;
  }
  .estate-details-wrapper {
    /*padding-top:91px;*/
    padding-bottom:83px;
  }
  .video-component, .estates-carousel-component {
    padding-top:58px;
    padding-bottom:93px;
  }
  .estates-carousel-component p {
    margin-top:25px;
  }
  .estates-carousel-component .left, .estates-carousel-component .right {
    top: 41%;
  }
  .word-carousel-nav {
    max-width:48%;
  }
  .estates-text-component {
    padding-top:100px;
    padding-bottom:80px;
  }
  .footer-logo-estates {
    margin-bottom:59px;
  }
  .design-line-wrapper {
    padding-top:76px;
    margin-bottom:144px;
  }
  .design-line-wrapper .images {
    transform:translateY(144px);
  }
  .interior-section {
    /*padding-top:93px;*/
    padding-bottom:93px;
  }
  .interior-caption {
    font-size:16px;
    line-height:36px;
  }
  .interior-content {
    font-size:14px;
    line-height:22px;
  }
  .amenities-list {
    padding-top:10px;
  }
  .amenities-list li {
    padding-top:14px;
    padding-bottom:15px;
    margin-bottom:0;
    font-size:18px;
  }
  .amenities-wrapper {
    padding-top:70px;
    padding-bottom:76px;
  }
  .estates-page .contact-form-section {
    padding-top:48px;
    padding-bottom:100px;
  }
  .cta-component {
    padding-top:63px;
    padding-bottom:63px;
  }
  .estates-page .table tr td {
    padding-top:16px;
    padding-bottom:17px;
  }
  .plans-section {
    padding-top:79px;
    padding-bottom:89px;
  }
  .estates-page .tab-content {
    padding-top:47px;
    padding-bottom:47px;
  }
  .estates-page .parallax-bg {
    height:324px;
  }
  .word-carousel-nav {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 35px;
    padding-top:0px;
    padding-left:0px;
    margin-top:0px;
  }
  .amenities-list li::before {
    margin-left: 35px;
  }
  .sustainability {
    padding-top:75px;
    padding-bottom:100px;
  }
  .price-breakdown {
    padding-bottom:100px;
  }
}
@media(min-width:992px) {
  .list-component {
/*    padding-top:75px;
    padding-bottom:150px;*/
    padding-top:80px;
    padding-bottom:80px;
  }
  .list-component li {
    font-size:22px;
    line-height:51px;
  }
  .list-component .heading {
    font-size:24px;
    line-height:31px;
    margin-bottom:23px;
  }
  .word-carousel .slick-slide img {
    height:547px;
  }
  .word-carousel-words {
    padding-top:75px;
  }
  .word-carousel-nav {
    bottom: 75px;
  }
  .with-arrow {
    max-width:229px;
  }
  .estate-content .slider-wrapper, .estate-content .slider-wrapper {
    margin-bottom:150px;
  }
  .sustainability {
    padding-top:100px;
    padding-bottom:70px;
  }
  .price-breakdown {
    padding-bottom:70px;
  }
  .cat-nav a {
    font: normal normal bold 14px/22px PF Futura Neu;
  }
  .cat-nav .seperator-cat-nav {
    font-size:24px;
  }
  .estates-page .parallax-bg {
    height:809px;
  }
  .estates-carousel-component p {
    margin-top:25px;
  }
  .estates-carousel-component .left, .estates-carousel-component .right {
    top: 43%;
    width:67px;
    height:67px;
  }
  .estates-text-component {
    padding-top:100px;
    padding-bottom:0px;
  }
  .price-breakdown .very-small-title {
    margin-bottom:36px;
  }
  .price-breakdown .item {
    padding-left:15px;
    padding-right:10px;
  }
  .amenities-list {
    margin-bottom:100px;
  }
  .word-carousel-heading {
    font-size: 50px;
    margin-bottom:10px;
  }
}
@media(min-width:1200px) {
  .page-header {
    /*margin-bottom:225px;*/
    height:560px;
  }
  .page-header h4 {
    /*margin-bottom:156px;*/
  }
  .estate-details-wrapper {
    /*padding-top:164px;*/
    padding-bottom:80px;
  }
  .estate-details .project-details {
    padding-top:81px;
  }
  .estate-details .project-details img {
    padding-top:81px;
  }
  /*  .project-details-component {
      padding-bottom:0px;
    }*/
  .video-component, .estates-carousel-component {
    padding-top:133px;
    padding-bottom:169px;
  }
  .design-line-wrapper {
    margin-bottom:144px;
    padding-top:121px;
  }
  .design-line-wrapper .images {
    transform:translateY(144px);
  }
  .footer-logo-estates {
    margin-bottom:46px;
  }
  .interior-section {
    /*padding-top:129px;*/
    padding-bottom:80px;
  }
  .interior-caption {
    font-size:16px;
    line-height:36px;
  }
  .interior-content {
    font-size:16px;
    line-height:30px;
  }
  .interior-row {
    padding-bottom:72px;
  }
  .interior-row.first {
    padding-top:81px;
  }
  .amenities-list li {
    padding-top:14px;
    padding-bottom:15px;
  }
  .amenities-wrapper {
    padding-top:121px;
    padding-bottom:169px;
  }
  .estates-page .contact-form-section {
    padding-top:121px;
    padding-bottom:92px;
  }
  .cta-component {
    padding-top:120px;
    padding-bottom:140px;
  }
  .cta-component h4 {
    font-size:60px;
    line-height:72px;
  }
  .estates-page .table tr td {
    padding-top:16px;
    padding-bottom:17px;
  }
  .plans-section {
    padding-top:121px;
    padding-bottom:138px;
  }
  .estates-page .tab-content {
    padding-top:34px;
    padding-bottom:72px;
  }
  .about-section .tagline, .estates-section .tagline {
    margin-bottom:15px;
  }
  .type3 .project-img {
    max-width:90%;
  }
  .interior-row:nth-child(1) .col-md-5 {
    padding-top:115px;
  }
  .interior-row:nth-child(2) .col-md-7, .interior-row:nth-child(3) .col-md-7, .interior-row:nth-child(4) .col-md-7 {
    padding-top:80px;
  }
}

@media(min-width:768px) and (max-width:1023px) {
  .activities-section .circle-menu {
    left: -1350px;
    top: -220px;
  }
  .activities-section svg foreignObject {
    transform: translate(35px, 15px);
  }
  .activities-section svg foreignObject div {
    font:normal normal bold 22px/26px PF Futura Neu;
  }
  .activities-section .indicators {
    top:37%;
  }
  .activities-section .indicators .slick-dots li button {
    width:31px;
  }
  .activities-section .indicators .slick-dots .slick-active button, .activities-section .indicators .slick-dots li button:focus, .activities-section .indicators .slick-dots li:hover button {
    width:125px;
  }
  .estates-section-wrapper .bg-img {
    max-width: 352px;
    top: 100px;
    /*top:0;*/
  }
}

@media(min-width:992px) and (max-width:1199px) {
  .estates-section-wrapper .bg-img {
    max-width: 352px;
    /*top: 100px;*/
    top:0;
  }
  .activities-section .circle-menu {
    top: -420px;
    left: -1325px;
  }
  /*  .activities-section .slick-slide .offset-lg-2 {
      margin-left:25%;
    }
    .activities-section .slick-slide .offset-lg-1 {
      margin-left:0px;
    }*/
  /*  .activities-section .indicators {
      display:none !important;
    }*/
}
@media(min-width:768px) {
  .soon-section img {
    min-width:512px;
  }
}

@media(min-width:1200px) and (max-width:1279px) {
  .header-navbar .navbar-nav .nav-link.estates-link, .header-navbar .navbar-nav .nav-link.main-link {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media(min-width:1200px) and (max-width:1365px) {
  .activities-section .circle-menu {
    left: -1100px;
    top: -320px;
  }
}

@media(min-height:550px) and (max-height:900px) and (min-width:768px) and (max-width:1200px) {
  .intro-section {
    padding-top:20%;
  }
  .estates-page .intro-section.new{
    padding-top:0px;
  }
}

@media(min-width:1200px) and (max-width:1599px) {
  .home-projects-section.visible-lines {
    background-position-x: -70vw;
  }
}

@media(min-width:1600px) and (max-width:1700px) {
  .home-projects-section.visible-lines {
    background-position-x: -60vw;
  }
}

@media(max-width:767px) {
  .word-carousel-heading {
    text-transform: uppercase;
    margin-bottom: 5px !important;
  }
}